export const CompanyReducer = (state =
                                   {
                                       name: null,
                                       registeredName: null,
                                       zipCode: null,
                                       city: null,
                                       street: null,
                                       taxNumber: null,
                                       registryNumber: null
                                   }, action) => {
    switch (action.type) {
        case "SET_NAME":
            return {...state, name: action.payload};

        case "SET_REGISTERED_NAME":
            return {...state, registeredName: action.payload};

        case "SET_ZIP_CODE":
            return {...state, zipCode: action.payload};

        case "SET_CITY":
            return {...state, city: action.payload};

        case "SET_STREET":
            return {...state, street: action.payload};

        case "SET_TAX_NUMBER":
            return {...state, taxNumber: action.payload};

        case "SET_REGISTRY_NUMBER":
            return {...state, registryNumber: action.payload};
        default:
            return state;
    }
};