import React, {Component} from "react";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import AppCSS from "../../App.scss";
import {StatusNavigate} from "./Order";
import CartContent from "../cart/CartContent";
import {bindActionCreators} from "redux";
import {getItemsCount, setEnableCart} from "../actions/CartActions";

class CartOrder extends Component {

    constructor(props) {
        super(props);

        this.props.setEnableCart(true);
    }

    disableCartData = () => {
        this.props.setEnableCart(false);
        this.props.next();
    };

    render() {
        return (
            <div>
                <h1 className={AppCSS.c3}>{this.props.translate("checkYourCart")}</h1>
                <p className={AppCSS.c1p5}>{this.props.translate("checkYourCartDescription")}</p>
                <hr/>
                <div>
                    <CartContent/>
                </div>
                <StatusNavigate next={this.props.getItemsCount() !== 0 ? this.disableCartData : null}
                                previous={this.props.previous} translate={this.props.translate}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getItemsCount: getItemsCount(state.cart)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        setEnableCart
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(CartOrder);