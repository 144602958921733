import React from 'react';

import {QuickBar, QuickLink} from '../QuickBar';

import SocialNetworksCSS from './SocialNetworks.scss';

import FacebookSVG from '../../images/svg-icons/socials/facebook-icon.svg';

const SocialNetworks = () => (
    <div className={SocialNetworksCSS.socialNetworks}>
        <QuickBar>
            <QuickLink title="Facebook" href="https://fb.me/chiptech.hu" imgSrc={FacebookSVG}/>
        </QuickBar>
    </div>
);

export default SocialNetworks;