import React from "react";
import {getActiveLanguage} from "react-localize-redux";
import {connect} from "react-redux";
import {MetaTags as ReactMetaTags} from "react-meta-tags";
import {getCompanyData} from "../actions/CompanyActions";

const MetaTags = (props) => {
    return (
        <ReactMetaTags>
            <meta name="author" content={props.getCompanyData().getName() + " " + window.location.hostname}/>
            <meta name='language' content={props.getActiveLanguage.code}/>
            <meta property='og:image:width' content='400'/>
            <meta property='og:image:height' content='400'/>

            <meta name="DC.format" content="text/html"/>
            <meta name="DC.type" content="Text"/>
            <meta name='DC.coverage' content={props.getActiveLanguage.name}/>
            <meta name="DC.identifier"
                  content={window.location.origin}/>
            <meta name="DC.publisher"
                  content={window.location.hostname}/>
        </ReactMetaTags>
    );
};

const mapStateToProps = state => {
    return {
        getActiveLanguage: getActiveLanguage(state.localize),
        getCompanyData: getCompanyData(state.company)
    };
};

export default connect(mapStateToProps)(MetaTags);