import React from 'react';

import BannerSVG from '../../images/big-banner.png';
import BannerCSS from './Banner.scss';

const Banner = (props) => (
    <div className={BannerCSS.logo}>
        <img src={BannerSVG} alt={props.title} title={props.title}/>
    </div>
);

export default Banner;