import React from "react";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {showDocViewer} from "../../components/actions/DocViewerActions";
import {connect} from "react-redux";

const GeneralTermsAndConditions = (props) => (
    <div>
        <a href="#" onClick={(e) => {
            e.preventDefault();
            props.showDocViewer(process.env.PUBLIC_URL + "/doc/general-terms-and-conditions.pdf", "application/pdf")
        }}>{props.translate("generalTermsAndConditions")}</a>
    </div>
);

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        showDocViewer
    }, dispatch);
};


export default connect(mapStateToProps, matchDispatchToProps)(GeneralTermsAndConditions);