import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {addTranslation, getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import SetMetaTags from "../../components/metas/SetMetaTags";
import classNames from "classnames";

import StockCSS from '../../components/Stock.scss';
import AppCSS from "../../App.scss";
import ContactUsCSS from './ContactUs.scss';
import {Flip} from "react-reveal";
import WriteUsForm from "./WriteUsForm";
import ContactUsJSON from "./ContactUs.json";
import GeneralTermsAndConditions from "../docs/GeneralTermsAndConditions";
import WithdrawalStatement from "../docs/WithdrawalStatement";
import {getCompanyData} from "../../components/actions/CompanyActions";
import {getContact} from "../../components/actions/ContactsActions";
import PrivacyPolicy from "../docs/PrivacyPolicy";

const Map = () => (
    <section className={classNames(StockCSS.white, ContactUsCSS.contactUsBackground)}>
        <div className={ContactUsCSS.map}>
            <iframe src={process.env.PUBLIC_URL + "map.html"}/>
        </div>
    </section>
);

const Contact = (props) => (
    <section className={classNames(StockCSS.white, StockCSS.skewLeftTop)}>
        <div className={StockCSS.stock}>
            <div className={classNames(AppCSS.appResponsive, StockCSS.preface, AppCSS.verticalTop)}>
                <Flip right>
                    <div>
                        <h1 className={AppCSS.c3}>{props.translate("contactUs")}</h1>
                        <div className={classNames(ContactUsCSS.appResponsive, ContactUsCSS.contactsTable)}>
                            <div className={AppCSS.c1p5}>{props.translate("getFullName", {
                                firstName: props.owner.getFirstName(),
                                lastName: props.owner.getLastName()
                            })}</div>
                            <div className={AppCSS.c1p5}>
                                <a href={"tel:" + props.owner.getPhoneNumber()}>{props.owner.getPhoneNumber()}</a>
                            </div>
                            <div className={AppCSS.c1p5}>{props.translate("emailAddress")}</div>
                            <div className={AppCSS.c1p5}>
                                <a href={"mailto:" + props.owner.getEmail()}>{props.owner.getEmail()}</a>
                            </div>
                            <div className={AppCSS.c1p5}>{props.translate("headquarters")}</div>
                            <div
                                className={AppCSS.c1p5}>{props.companyData.getZipCode()} {props.companyData.getCity()} {props.companyData.getStreet()}</div>
                            <div>{props.translate("taxNumber")}</div>
                            <div>{props.companyData.getTaxNumber()}</div>
                            <div>{props.translate("registryNumber")}</div>
                            <div>{props.companyData.getRegistryNumber()}</div>
                            <div><GeneralTermsAndConditions/></div>
                            <div><WithdrawalStatement/></div>
                            <div><PrivacyPolicy/></div>
                        </div>
                    </div>
                </Flip>
                <div>
                    <Flip top>
                        <h2 className={AppCSS.c3}>{props.translate("writeUs")}</h2>
                    </Flip>
                    <WriteUsForm/>
                </div>
            </div>
        </div>
    </section>
);

class ContactUs extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(ContactUsJSON);
    }

    render() {
        return (
            <span>
                <SetMetaTags title={this.props.translate("contact")}
                             description={this.props.translate("contactUsMetaDescription")}/>
                <Map/>
                <Contact companyData={this.props.getCompanyData()}
                         owner={this.props.getContact("owner")}
                         translate={this.props.translate}/>
            </span>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getCompanyData: getCompanyData(state.company),
        getContact: getContact(state.contacts)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(ContactUs);