import React, {Component} from 'react';
import {addTranslation, getTranslate} from "react-localize-redux";
import {bindActionCreators} from 'redux';
import {connect} from "react-redux";
import FooterJSON from "../../languages/Footer";
import FooterCSS from "./Footer.scss";
import {getCompanyData} from "../actions/CompanyActions";

class Footer extends Component {

    constructor(props) {
        super(props);
        this.props.addTranslation(FooterJSON);
    }

    render() {
        return (
            <footer className={FooterCSS.footer}>
                <div>{this.props.getCompanyData().getName()}</div>
                2018 © Copyright {window.location.hostname} | {this.props.translate("allRightsReserved")}!
                <div>Developed by <a href="https://www.linkedin.com/in/mark-jehoda"
                                     target="_blank"
                                     rel="noopener noreferrer">Mark J.</a>
                </div>
            </footer>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getCompanyData: getCompanyData(state.company)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Footer);