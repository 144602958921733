import React, {Component} from 'react';
import {addTranslation, getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import AppCSS from "../App.scss";
import LoadingCSS from "./Loading.scss";
import LoadingSVG from '../images/svg-icons/loading.svg';
import {Flip} from "react-reveal";
import LoadingJSON from "../languages/Loading";

class Loading extends Component {

    constructor(props) {
        super(props);
        this.props.addTranslation(LoadingJSON);

        this.isText = this.props.isText ? this.props.isText : false;

    }

    render() {
        return (
            <div className={LoadingCSS.loadingContent}>
                <img className={LoadingCSS.loadingImg}
                     src={LoadingSVG}/>{
                this.isText ?
                    <span className={AppCSS.c2}>
                        <Flip top cascade delay={1000}>
                            {this.props.translate("pleaseWait")}
                        </Flip>
                    </span>
                    : null
            }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = (dispatch) => {
    return bindActionCreators({addTranslation}, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Loading);