import React, {Component, createRef} from "react";
import classNames from "classnames";

import AppCSS from "../App.scss";
import ScrollTopCSS from "./ScrollTop.scss";

const iconVisiblePosition = 200;
const step = /Firefox/i.test(navigator.userAgent) ? 160 : 80;

class ScrollTop extends Component {

    constructor(props) {
        super(props);

        this.iconRef = createRef();
    }

    componentDidMount() {
        window.addEventListener('scroll', () => {
            let pos = window.pageYOffset > iconVisiblePosition ? -5 : -65;
            this.iconRef.current.style.right = pos + "px";
        }, {passive: true});
    }

    goTop = () => {
        if (window.pageYOffset > step) {
            window.scrollBy(0, -step);
        } else {
            window.scrollTo(0, 0);
        }

        if (window.pageYOffset !== 0) {
            window.requestAnimationFrame(this.goTop);
        }
    };

    render() {
        return (
            <div className={classNames(AppCSS.appCircleButton, ScrollTopCSS.scrollTopBlock)}
                 onClick={this.goTop}
                 ref={this.iconRef}
            >
                <i className={AppCSS.materialIcons}>keyboard_arrow_up</i>
            </div>
        );
    }
}

export default ScrollTop;