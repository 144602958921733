import React, {Component} from "react";
import {getTranslate} from "react-localize-redux";
import {deleteAllItems} from "../actions/CartActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AppCSS from "../../App.scss";
import {Flip} from "react-reveal";
import StockCSS from '../../components/Stock.scss';
import SuccessSVG from "../../images/svg-icons/status/success.svg";

class SucceededOrder extends Component {

    constructor(props) {
        super(props);

        this.props.deleteAllItems();
    }

    render() {
        return (
            <div className={AppCSS.appResponsive}>
                <div>
                    <Flip left>
                        <img className={StockCSS.stockImg} src={SuccessSVG}
                             alt={this.props.translate("succeedOrder")}/>
                    </Flip>
                </div>
                <div>
                    <h1 className={AppCSS.c3}>{this.props.translate("succeedOrder")}</h1>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        deleteAllItems
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(SucceededOrder);