import React, {Component} from "react";
import {bindActionCreators} from "redux";
import {addTranslation, getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import SetMetaTags from "../../../components/metas/SetMetaTags";
import classNames from "classnames";
import StockCSS from '../../../components/Stock.scss';
import AppCSS from "../../../App.scss";
import ActivationCSS from './Activation.scss';
import ActivationJSON from "./Activation.json";
import ForgotPasswordActivation from "./ForgotPasswordActivation";
import QuestionSVG from "../../../images/svg-icons/status/question.svg";
import SuccessSVG from "../../../images/svg-icons/status/success.svg";
import ErrorSVG from "../../../images/svg-icons/status/error.svg";
import SignUpActivation from "./SignUpActivation";

const ActivationContent = (props) => {
    switch (props.mode) {
        case "sign-up":
            return (<SignUpActivation activationkey={props.activationkey} setSuccess={props.setSuccess}/>);
        case "forgot-password":
            return (<ForgotPasswordActivation activationkey={props.activationkey} setSuccess={props.setSuccess}/>);
        default:
            return (<div>Ilyen aktivációs mód nem létezik!</div>);
    }
};

class Activation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            success: null
        };

        this.props.addTranslation(ActivationJSON);
    }

    setSuccess = success => {
        this.setState(prevState => ({...prevState, success}));
    };

    render() {
        return (
            <span>
                <SetMetaTags title={this.props.translate("activation")}/>
                    <section className={StockCSS.white}>
                        <div className={StockCSS.stock}>
                            <div className={AppCSS.appResponsive}>
                                <div
                                    className={classNames(ActivationCSS.activationImageFlipCard, this.state.success !== null ? ActivationCSS.visible : null)}>
                                    <figure>
                                        <img src={QuestionSVG} alt="Status"/>
                                    </figure>
                                    <figure className={ActivationCSS.activationImageBack}>
                                        <img src={this.state.success ? SuccessSVG : ErrorSVG} alt="Status"/>
                                    </figure>
                                </div>
                                <div>
                                    <ActivationContent activationkey={this.props.match.params.key}
                                                       mode={this.props.match.params.mode}
                                                       setSuccess={this.setSuccess}/>
                                </div>
                            </div>
                        </div>
                    </section>
            </span>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Activation);