import React, {Component} from "react";
import ShopJSON from "./Shop.json";
import ShopFormJSON from "./ShopForm.json";
import SetMetaTags from "../../components/metas/SetMetaTags";
import {addTranslation, getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import StockCSS from "../../components/Stock.scss";
import EmptyGroupPictureSVG from "../../images/svg-icons/shop/empty-group.svg";
import ErrorGroupPictureSVG from "../../images/svg-icons/shop/error-group.svg";
import {addNotice} from "../../components/actions/NoticeActions";
import {setGroups, setProducts} from "../actions/ShopActions";
import Error from "../../components/Error";
import {ProductsList} from "./Products";
import {GroupsList} from "./Groups";
import classNames from "classnames";
import AppCSS from "../../App.scss";
import ShopSearch from "./ShopSearch";
import Loading from "../../components/Loading";
import {Fade} from "react-reveal";
import {api} from "../../controllers/API";

class Shop extends Component {

    constructor(props) {
        super(props);

        this.state = {isError: false, isEmpty: false, isLoading: true};

        this.getShop(this.getPath(props.match.params));

        this.props.addTranslation(ShopJSON);
        this.props.addTranslation(ShopFormJSON);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.path !== prevProps.match.params.path) {
            this.setState(prevState => ({...prevState, isLoading: true}));
            this.getShop(this.getPath(this.props.match.params));
        }
    }

    getPath = (params) => {
        const data = [];

        if (params.selected === "search") {
            data.push("/shop/search");
            if (params.path) {
                data.push(params.path);
            }
        } else {
            data.push("/shop/products");
            if (params.path) {
                data.push(params.path);
            }
        }
        return data.join("/");
    };

    getShop = (path) => {
        api.get(path).then(response => {
            const newState = {isLoading: false};
            if (response.data.status) {
                this.props.setGroups(response.data.data.groups);
                this.props.setProducts(response.data.data.products);
                newState.isError = response.data.data.groups.length === 0 && response.data.data.products.length === 0 ? 1 : 0;
            } else {
                this.props.addNotice(response.data.notice);
                newState.isError = 2;
            }

            this.setState(prevState => ({...prevState, ...newState}));
        }).catch(error => {
            this.setState(prevState => ({...prevState, isError: 2, isLoading: false}));
            console.log(error);
            this.props.addNotice(error.message);
        });
    };

    render() {
        return (
            <span>
                <SetMetaTags title={this.props.translate("ourProducts")}
                             description={this.props.translate("ourProductsMetaDescription")}/>
                <section className={classNames(StockCSS.blue, StockCSS.skewLeftBottom)}>
                    <div className={StockCSS.stock}>
                        <div className={StockCSS.preface}>
                            <Fade top>
                                <div>
                                    <h1 className={AppCSS.c3}>{this.props.translate("ourProducts")}</h1>
                                </div>
                            </Fade>
                            <ShopSearch history={this.props.history}/>
                        </div>
                    </div>
                </section>
                <section className={StockCSS.white}>
                    {this.state.isLoading ?
                        <Loading isText={true}/> : (
                            this.state.isError !== 0 ?
                                <Error imgSrc={this.state.isError === 1 ? EmptyGroupPictureSVG : ErrorGroupPictureSVG}
                                       title={this.props.translate(this.state.isError === 1 ? "emptyGroup" : "errorGroup")}/> :
                                <div>
                                    <div className={StockCSS.stock}>
                                        <GroupsList groups={this.props.shop.groups}/>
                                    </div>
                                    <div className={StockCSS.stock}>
                                        <ProductsList products={this.props.shop.products}
                                                      translate={this.props.translate}/>
                                    </div>
                                </div>)
                    }
                </section>
            </span>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        shop: state.shop
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation,
        addNotice,
        setProducts,
        setGroups
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Shop);