import React, {Component} from "react";
import classNames from "classnames";

import StockCSS from '../../components/Stock.scss';
import UserBlockCSS from './UserBlock.scss';
import AppCSS from "../../App.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {QuickBar, QuickButton} from "../../components/QuickBar";

import SignUpSVG from "../../images/svg-icons/user/user-sign-up.svg";
import ForgotPasswordSVG from "../../images/svg-icons/user/forgot-password.svg";
import PopUp, {PopUpContent, PopUpPadding} from "../../components/PopUp";
import PopUpCSS from "../../components/PopUp.scss";
import {addTranslation, getTranslate} from "react-localize-redux";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import GeneralTermsAndConditions from "../docs/GeneralTermsAndConditions";
import PrivacyPolicy from "../docs/PrivacyPolicy";

const SignInPopUpsContent = (props) => (
    <PopUpContent>
        <PopUpPadding>
            <div className={classNames(AppCSS.appResponsive, AppCSS.verticalTop)}>
                <div>
                    <h1 className={classNames(PopUpCSS.popupTitle, StockCSS.blue, StockCSS.emphasize, AppCSS.c2)}>{props.translate("signUp")}</h1>
                    <p>{props.translate("signUpDescription")}</p>
                    <div className={AppCSS.appResponsive}>
                        <GeneralTermsAndConditions/>
                        <PrivacyPolicy/>
                    </div>
                    <SignUpForm/>
                </div>
                <div>
                    <h1 className={classNames(PopUpCSS.popupTitle, StockCSS.blue, StockCSS.emphasize, AppCSS.c2)}>{props.translate("forgotPassword")}</h1>
                    <p>{props.translate("forgotPasswordDescription")}</p>
                    <ForgotPasswordForm/>
                </div>
            </div>
        </PopUpPadding>
    </PopUpContent>
);

class SignInBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
    }

    render() {
        return (
            <span>
                <div className={classNames(UserBlockCSS.userBlockContent, StockCSS.white)}>
                    <SignInForm>
                        <div>
                            <QuickBar>
                                <QuickButton title={this.props.translate("signUp")}
                                             onClick={() => this.setState(prevState => ({
                                                 ...prevState,
                                                 visible: true
                                             }))}
                                             imgSrc={SignUpSVG}/>
                                <QuickButton title={this.props.translate("forgotPassword")}
                                             onClick={() => this.setState(prevState => ({
                                                 ...prevState,
                                                 visible: true
                                             }))}
                                             imgSrc={ForgotPasswordSVG}/>
                            </QuickBar>
                        </div>
                    </SignInForm>

                </div>
                <PopUp visible={this.state.visible}
                       onHide={() => this.setState(prevState => ({
                           ...prevState,
                           visible: false
                       }))}
                       isCloseButton={true}>
                    <SignInPopUpsContent translate={this.props.translate}/>
                </PopUp>
            </span>
        );
    }

}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation,
    }, dispatch);
};


export default connect(mapStateToProps, matchDispatchToProps)(SignInBlock);