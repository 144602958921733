import {api} from "./API";

export default class APIProgress {

    constructor(start, increase, end) {
        this.requestThreadsNumber = 0;
        this.setStart = start;
        this.setIncrease = increase;
        this.setEnd = end;

        api.defaults.onUploadProgress = this.onProgress;
        api.defaults.onDownloadProgress = this.onProgress;

        api.interceptors.request.use(config => {
            this.start();
            return config;
        });

        api.interceptors.response.use(this.response, this.error);
    }

    onProgress = () => {
        this.setIncrease();
    };

    start = () => {
        if (this.requestThreadsNumber === 0) {
            this.setStart();
        }
        this.requestThreadsNumber++;
    };

    end = () => {
        if ((--this.requestThreadsNumber) === 0) {
            this.setEnd();
        }
    };

    response = response => {
        this.end();
        return response;
    };

    error = error => {
        this.end();
        return Promise.reject(error);
    };
}