import React, {Component} from "react";
import classNames from "classnames";
import StockCSS from "../../components/Stock.scss";
import AppCSS from "../../App.scss";
import {Fade, Slide} from "react-reveal";
import {getTranslate} from "react-localize-redux";
import connect from "react-redux/es/connect/connect";
import MCUPCBJPG from "../../images/home/mcu-pcb.jpg";
import BrakeLightJPG from "../../images/home/brake-light.jpg";
import SlideShowCSS from "./SlideShow.scss";

const FirstSlide = props => (
    <div className={classNames(AppCSS.appResponsive, StockCSS.preface, SlideShowCSS.slideShowItem)}>
        <Slide left opposite cascade big when={props.show}>
            <div>
                <img className={StockCSS.stockImg}
                     src={MCUPCBJPG}
                     alt="PCB"
                     title="PCB"/>
            </div>
            <div>
                <h1 className={AppCSS.c3}>{props.translate("homeFirstSlideDescription1")}</h1>
                <hr/>
                <h3 className={AppCSS.c2}>{props.translate("homeFirstSlideDescription2")}</h3>
            </div>
        </Slide>
    </div>
);

const SecondSlide = props => (
    <div className={classNames(AppCSS.appResponsive, StockCSS.preface, SlideShowCSS.slideShowItem)}>
        <Slide left opposite cascade big when={props.show}>
            <div>
                <img className={StockCSS.stockImg}
                     src={BrakeLightJPG}
                     alt="Brake light"
                     title="Brake light"/>
            </div>
            <div>
                <h1 className={AppCSS.c3}>{props.translate("homeSecondSlideDescription1")}</h1>
            </div>
        </Slide>
    </div>
);

class SlideShow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: 0
        };

        this.timer = setInterval(() => this.setState(prevState => ({
            ...prevState,
            show: (prevState.show === 1 ? 0 : ++prevState.show)
        })), 6000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (
            <section className={StockCSS.white}>
                <div>
                    <Fade bottom opposite when={this.state.show === 0}>
                        <div
                            className={classNames(SlideShowCSS.slideShowBackground, SlideShowCSS.slideShowBackgroundFirst)}/>
                    </Fade>
                    <Fade bottom opposite when={this.state.show === 1}>
                        <div
                            className={classNames(SlideShowCSS.slideShowBackground, SlideShowCSS.slideShowBackgroundSecond)}/>
                    </Fade>
                </div>
                <div className={classNames(StockCSS.stock, SlideShowCSS.slideShowContainer)}>
                    <FirstSlide show={this.state.show === 0} translate={this.props.translate}/>
                    <SecondSlide show={this.state.show === 1} translate={this.props.translate}/>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

export default connect(mapStateToProps, null)(SlideShow);