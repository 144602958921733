import {loadCartFromLocalStorage} from "../actions/CartActions";

export const CartReducer = (
    state = {
        cart: loadCartFromLocalStorage(),
        lastModified: Date.now(),
        isEnable: true
    },
    action) => {
    switch (action.type) {
        case "DELETE_ITEMS_FROM_CART":
            if (action.payload.key) {
                const newState = {...state};
                delete newState.cart[action.payload.key];
                newState.lastModified = Date.now();
                return newState;
            } else {
                return {cart: {}, lastModified: Date.now(), isEnable: true};
            }

        case "UPDATE_CART_ITEM":
            const updateItemPiece = {...state};
            updateItemPiece.cart[action.payload.key].piece = action.payload.piece;
            updateItemPiece.lastModified = Date.now();
            return updateItemPiece;

        case "ADD_CART_ITEM":
            const newState = {...state};
            if (newState.cart[action.payload.key]) {
                newState.cart[action.payload.key].piece += action.payload.item.piece;
            } else {
                newState.cart[action.payload.key] = action.payload.item;
            }
            newState.lastModified = Date.now();
            return newState;
        case "SET_CART_ENABLE":
            return {...state, isEnable: action.payload.isEnable};
        default:
            return state;
    }
};