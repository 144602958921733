import React, {Component} from "react";
import AppCSS from "../../App.scss";
import CartSumPriceCSS from "./CartSumPrice.scss";
import {getTranslate} from "react-localize-redux";
import classNames from "classnames";
import {connect} from "react-redux";
import {getCartContent} from "../actions/CartActions";

class CartSumPrice extends Component {

    calcSumPrice = (items) => {
        let sumPrice = 0;

        Object.keys(items).forEach(key => {
            sumPrice += items[key].piece * items[key].unitPrice;
        });
        return sumPrice;
    };

    render() {
        return (
            <div className={CartSumPriceCSS.cartSumPriceContent}>
                <div
                    className={classNames(CartSumPriceCSS.totalPriceTitle, AppCSS.c1p5)}>{this.props.translate("totalCartPrice")}</div>
                <div
                    className={classNames(CartSumPriceCSS.totalPrice, AppCSS.c1p5)}>{this.calcSumPrice(this.props.getCartContent())} Ft
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getCartContent: getCartContent(state.cart),
    };
};


export default connect(mapStateToProps, null)(CartSumPrice);