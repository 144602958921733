import React, {Component} from "react";
import classNames from "classnames";
import AppCSS from "../../App.scss";
import {bindActionCreators} from "redux";
import {addNotice} from "../../components/actions/NoticeActions";
import {connect} from "react-redux";
import {getID, setUserSession} from "../../components/actions/UserSessionActions";
import {clearProfileData} from "../actions/ProfileActions";
import {getTranslate} from "react-localize-redux";
import {clearShippingData} from "../actions/ShippingActions";
import {clearBillingData} from "../actions/BillingActions";
import {api} from "../../controllers/API";

class SignOutForm extends Component {

    constructor(props) {
        super(props);
    }

    signOut = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        api.post("/user/" + this.props.getID() + "/sign-out", formData)
            .then(response => {
                this.props.setUserSession(null, null, null);
                this.props.clearProfileData();
                this.props.clearShippingData();
                this.props.clearBillingData();
                this.props.addNotice(response.data.notice);
            })
            .catch(error => {
                console.log(error);
                this.props.addNotice(error.message);
            });

    };

    render() {

        return (
            <form onSubmit={this.signOut}>
                <button className={classNames(AppCSS.appButton, AppCSS.c1p5)}>{this.props.translate("signOut")}</button>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getID: getID(state.userSession)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice,
        setUserSession,
        clearProfileData,
        clearShippingData,
        clearBillingData
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(SignOutForm);