import React, {Component} from "react";
import {connect} from "react-redux";
import {showPhotoViewer} from "../actions/PhotoViewerActions";
import {bindActionCreators} from "redux";
import classNames from "classnames";

import PhotoViewerCSS from './PhotoViewer.scss';
import {photos} from "./PhotoViewer";

class Photo extends Component {

    constructor(props) {
        super(props);

        this.index = null;

        if (this.props.group) {
            this.group = this.props.group;
        } else {
            this.group = Object.keys(photos).length;
        }

        const src = this.props.src ? this.props.src : "";
        const title = this.props.title ? this.props.title : "";

        if (this.group in photos === false) {
            photos[this.group] = [];
            this.index = 0;
        } else {
            this.index = photos[this.group].length;
        }

        photos[this.group].push({src, title});

        this.imgProps = Object.assign({}, this.props);
        delete this.imgProps.showPhotoViewer;
        if (this.imgProps.group) {
            delete this.imgProps.group;
        }
    }

    componentWillUnmount() {
        if (photos[this.group].length > 1) {
            delete photos[this.group][this.index];
            photos[this.group].length--;
        } else {
            delete photos[this.group];
        }
    }

    render() {
        return (
            <img {...this.imgProps}
                 className={classNames(this.props.className, PhotoViewerCSS.photoViewerImg)}
                 onClick={() => this.props.showPhotoViewer(this.group, this.index)}/>
        );
    }

}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({showPhotoViewer}, dispatch);
};

export default connect(null, matchDispatchToProps)(Photo);