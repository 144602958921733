export const defaultProfileData = {
    email: null,
    firstName: null,
    lastName: null,
    lastSignIn: null
};

export const setProfileData = (profileData) => (
    {type: "SET_PROFILE_DATA", payload: profileData}
);

export const clearProfileData = () => (
    {
        type: "SET_PROFILE_DATA", payload: defaultProfileData
    }
);

export const getProfileData = (state) => (
    () => (
        {
            getEmail: () => (state.email),
            getFirstName: () => (state.firstName),
            getLastName: () => (state.lastName),
            getLastSignIn: () => (state.lastSignIn)
        }
    )
);