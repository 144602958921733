import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {getTranslate} from 'react-localize-redux';
import classNames from "classnames";

import AppCSS from "../../App.scss";
import MenuCSS from './Menu.scss';
import Banner from "./Banner";
import {getCompanyData} from "../actions/CompanyActions";

const MenuList = (props) => (
    <ul onClick={props.hide}>
        {props.children}
    </ul>
);

export const MenuItem = (props) => (
    <li>
        <NavLink className={AppCSS.c1} to={props.to}>{props.title}</NavLink>
    </li>
);

export const Highlight = (props) => (
    <span className={MenuCSS.highlight}>
        {props.children}
    </span>
);

class MenuButton extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span className={classNames(this.props.toggleClass, MenuCSS.menuButton)}
                  onClick={this.props.toggle}>
            <div className={MenuCSS.menuIcon}>
                <span className={MenuCSS.menuIconsBar}/>
                <span className={MenuCSS.menuIconsBar}/>
                <span className={MenuCSS.menuIconsBar}/>
            </div>
        </span>
        )
    }
}


class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {toggleClass: false};
    }

    toggle = () => {
        this.setState({toggleClass: this.state.toggleClass ? null : MenuCSS.menuButtonEvent});
    };

    hide = () => {
        this.setState(prevState => ({...prevState, toggleClass: null}));
    };

    render() {
        return (
            <nav className={MenuCSS.menuBar}>
                <Banner title={this.props.getCompanyData().getName()}/>
                <MenuButton toggleClass={this.state.toggleClass} toggle={this.toggle}/>
                <MenuList hide={this.hide}>
                    {this.props.children}
                </MenuList>
            </nav>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getCompanyData: getCompanyData(state.company)
    };
};

export default connect(mapStateToProps, null)(Menu);