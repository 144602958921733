import React, {Component} from "react";
import classNames from "classnames";
import StockCSS from '../../components/Stock.scss';
import AppCSS from "../../App.scss";
import {bindActionCreators} from "redux";
import {addNotice} from "../../components/actions/NoticeActions";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import InputChecker from "../../controllers/InputChecker";
import {getProfileData, setProfileData} from "../actions/ProfileActions";
import {getID} from "../../components/actions/UserSessionActions";
import {api} from "../../controllers/API";

class UpdateProfileForm extends Component {

    constructor(props) {
        super(props);
        this.ic = new InputChecker();
    }

    update = (e) => {
        e.preventDefault();

        if (this.ic.empty(e.target.elements.firstName.value)) {
            this.ic.setErrorColor(e.target.elements.firstName);
            this.props.addNotice(this.props.translate("emptyFirstName"));
        }

        if (this.ic.empty(e.target.elements.lastName.value)) {
            this.ic.setErrorColor(e.target.elements.lastName);
            this.props.addNotice(this.props.translate("emptyLastName"));
        }

        if (this.ic.empty(e.target.elements.email.value)) {
            this.ic.setErrorColor(e.target.elements.email);
            this.props.addNotice(this.props.translate("emptyEmail"));
        }

        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        const formData = new FormData(e.target);

        api.post("/user/" + this.props.getID() + "/profile", formData)
            .then(response => {
                if (response.data.status) {
                    this.props.setProfileData(response.data.data.profile);
                }
                this.props.addNotice(response.data.notice);
            })
            .catch(error => {
                console.log(error);
                this.props.addNotice(error.message);
            });

    };

    render() {

        return (
            <form onSubmit={this.update}>
                <div>
                    <div>
                        <input type="text"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="firstName"
                               defaultValue={this.props.getProfileData().getFirstName()}
                               placeholder={this.props.translate("yourFirstName")}/>
                    </div>
                    <div>
                        <input type="text"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="lastName"
                               defaultValue={this.props.getProfileData().getLastName()}
                               placeholder={this.props.translate("yourLastName")}/>
                    </div>
                    <div>
                        <input type="text"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="email"
                               defaultValue={this.props.getProfileData().getEmail()}
                               placeholder={this.props.translate("yourEmail")}/>
                    </div>
                    <div>
                        <button
                            className={classNames(AppCSS.appButton, AppCSS.c2)}>{this.props.translate("save")}</button>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getProfileData: getProfileData(state.profile),
        getID: getID(state.userSession)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice,
        setProfileData
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(UpdateProfileForm);