import {loadUserSessionFromSessionStorage} from "../actions/UserSessionActions";

export const UserSessionReducer = (state = {
    ...loadUserSessionFromSessionStorage(),
}, action) => {
    switch (action.type) {
        case "SET_USER_SESSION":
            return {
                ...state,
                token: action.payload.token,
                id: action.payload.id,
                expiration: action.payload.expiration
            };
        case "SET_USER_SESSION_TOKEN":
            return {
                ...state,
                token: action.payload.token
            };
        default:
            return state;
    }
};