export const defaultShippingData = {
    zipCode: null,
    city: null,
    street: null,
    phoneNumber: null
};

export const setShippingData = (shippingData) => (
    {type: "SET_SHIPPING_DATA", payload: shippingData}
);

export const clearShippingData = () => (
    {
        type: "SET_SHIPPING_DATA", payload: defaultShippingData
    }
);

export const getShippingData = (state) => (
    () => (
        {
            getZipCode: () => (state.zipCode),
            getCity: () => (state.city),
            getStreet: () => (state.street),
            getPhoneNumber: () => (state.phoneNumber)
        }
    )
);