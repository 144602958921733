import React, {Component} from "react";

import "./PhotoViewer.scss";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import classNames from "classnames";

import AppCSS from "../../App.scss";
import StockCSS from "../Stock.scss";
import PopUpCSS from "../PopUp.scss";
import PhotoViewerCSS from './PhotoViewer.scss';
import {hidePhotoViewer} from "../actions/PhotoViewerActions";
import PopUp from "../PopUp";

export const photos = {};

class PhotoViewer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            src: null,
            title: null,
            next: false,
            prev: false
        };

        this.index = 0;
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.photoViewer.visible && photos[this.props.photoViewer.group]) {
            if (photos[this.props.photoViewer.group][this.props.photoViewer.startIndex]) {
                this.index = this.props.photoViewer.startIndex;
                this.setImg(this.index);
            }
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", (e) => {
            switch (e.key) {
                case "ArrowLeft":
                    this.prev();
                    break;
                case "ArrowRight":
                    this.next();
                    break;
                case "Escape":
                    this.props.hidePhotoViewer();
                    break;
            }
        }, {passive: true});
    }

    setImg = (index) => {
        this.setState({
            src: photos[this.props.photoViewer.group][index].src,
            title: photos[this.props.photoViewer.group][index].title,
            next: photos[this.props.photoViewer.group].length - 1 !== index,
            prev: index !== 0
        });
    };

    prev = () => {
        if (this.props.photoViewer.visible && this.index > 0) {
            this.setImg(--this.index);
        }
    };

    next = () => {
        if (this.props.photoViewer.visible && this.index < photos[this.props.photoViewer.group].length - 1) {
            this.setImg(++this.index);
        }
    };

    render() {
        return (
            <PopUp visible={this.props.photoViewer.visible} onHide={() => this.props.hidePhotoViewer()}
                   isCloseButton={true}>
                <div className={PhotoViewerCSS.photoViewerBlock}>
                    <div title={this.props.translate("previous")}
                         className={classNames(AppCSS.appCircleButton, (this.state.prev ? null : PhotoViewerCSS.hide), PhotoViewerCSS.photoViewerPrevious)}
                         onClick={this.prev}>
                        <i className={AppCSS.materialIcons}>navigate_before</i>
                    </div>
                    <div title={this.props.translate("next")}
                         className={classNames(AppCSS.appCircleButton, (this.state.next ? null : PhotoViewerCSS.hide), PhotoViewerCSS.photoViewerNext)}
                         onClick={this.next}>
                        <i className={AppCSS.materialIcons}>navigate_next</i>
                    </div>
                    <div className={PhotoViewerCSS.photoViewerTitleBlock}>
                        <h1 className={classNames(PopUpCSS.popupTitle, AppCSS.c2, StockCSS.blue, StockCSS.emphasize)}>{this.state.title}</h1>
                    </div>
                    <div className={PhotoViewerCSS.photoViewerOpenedImg} style={{
                        backgroundImage: "url('" + this.state.src + "')"
                    }} title={this.state.title}/>
                </div>
            </PopUp>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        photoViewer: state.photoViewer
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        hidePhotoViewer
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(PhotoViewer);