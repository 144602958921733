import React, {Component} from "react";
import AppCSS from "../../App.scss";
import CartFooterCSS from "./CartFooter.scss";
import {QuickBar, QuickButton} from "../../components/QuickBar";
import EmptyingCartSVG from "../../images/svg-icons/shop/emptying-cart.svg";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {deleteAllItems, isEnableCart} from "../actions/CartActions";
import {addNotice} from "../../components/actions/NoticeActions";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import {Link} from "react-router-dom";

class CartFooter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmPopUpVisible: false
        }
    }

    deleteAllItemsFromCart = () => {
        if (this.props.isEnableCart()) {
            this.props.deleteAllItems();
            this.props.addNotice(this.props.translate("successfullyEmptiedTheCart"));
        } else {
            this.props.addNotice(this.props.translate("theCartIsLocked"));
        }
        this.setState(prevSate => ({...prevSate, confirmPopUpVisible: false}));
    };

    render() {
        return (
            <div className={CartFooterCSS.cartFooterContent}>
                <div>
                    <Link to={"/order"} onClick={this.props.closeCartBlock}
                          className={AppCSS.appButton}>{this.props.translate("order")}</Link>
                </div>
                <div>
                    <QuickBar>
                        <QuickButton title={this.props.translate("emptyingCart")}
                                     onClick={() => (this.setState(prevState => ({
                                         ...prevState,
                                         confirmPopUpVisible: true
                                     })))}
                                     imgSrc={EmptyingCartSVG}/>
                    </QuickBar>
                </div>
                <ConfirmPopUp visible={this.state.confirmPopUpVisible}
                              question={this.props.translate("questionBeforeEmptyingCart")}
                              noEvent={() => (this.setState(prevState => ({...prevState, confirmPopUpVisible: false})))}
                              yesEvent={this.deleteAllItemsFromCart}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        isEnableCart: isEnableCart(state.cart)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        deleteAllItems,
        addNotice
    }, dispatch);
};


export default connect(mapStateToProps, matchDispatchToProps)(CartFooter);