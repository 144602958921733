import React, {Component} from "react";
import classNames from "classnames";
import StockCSS from '../../components/Stock.scss';
import AppCSS from "../../App.scss";
import {bindActionCreators} from "redux";
import {addNotice} from "../../components/actions/NoticeActions";
import {connect} from "react-redux";
import InputChecker from "../../controllers/InputChecker";
import {setUserSession} from "../../components/actions/UserSessionActions";
import {getTranslate} from "react-localize-redux";
import {api} from "../../controllers/API";

class SignInForm extends Component {

    constructor(props) {
        super(props);
        this.ic = new InputChecker();
    }

    signIn = (e) => {
        e.preventDefault();

        if (this.ic.empty(e.target.elements.email.value)) {
            this.ic.setErrorColor(e.target.elements.email);
            this.props.addNotice(this.props.translate("emptyEmail"));
        }

        if (this.ic.empty(e.target.elements.password.value)) {
            this.ic.setErrorColor(e.target.elements.password);
            this.props.addNotice(this.props.translate("emptyPassword"));
        }

        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        const formData = new FormData(e.target);

        api.post("/user/sign-in", formData)
            .then(response => {
                if (response.data.status) {
                    this.props.setUserSession(
                        response.data.data.token,
                        response.data.data.user.id,
                        response.data.data.expiration
                    );
                }
                this.props.addNotice(response.data.notice);
            })
            .catch(error => {
                console.log(error);
                this.props.addNotice(error.message);
            });

    };

    render() {

        return (
            <form onSubmit={this.signIn}>
                <div>
                    <div>
                        <input type="email"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="email"
                               placeholder={this.props.translate("yourEmail")}/>
                    </div>
                    <div>
                        <input type="password"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="password"
                               placeholder={this.props.translate("yourPassword")}/>
                    </div>
                    <div className={AppCSS.appResponsive}>
                        <div>
                            <button
                                className={classNames(AppCSS.appButton, AppCSS.c1p5)}>{this.props.translate("signIn")}</button>
                        </div>
                        <div>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice,
        setUserSession
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(SignInForm);