import {combineReducers} from 'redux';
import {GlobalReducers} from "../../components/reducers/Reducers";
import {ProfileReducer} from "./ProfileReducer";
import {ShippingReducer} from "./ShippingReducer";
import {BillingReducer} from "./BillingReducer";
import {CartReducer} from "./CartReducer";
import {ShopReducer} from "./ShopReducer";

const GuestReducers = combineReducers({
    ...GlobalReducers,
    profile: ProfileReducer,
    shipping: ShippingReducer,
    billing: BillingReducer,
    cart: CartReducer,
    shop: ShopReducer,
});

export default GuestReducers;