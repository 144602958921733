import React, {Component} from 'react';
import {addTranslation, getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Fade, Flip} from 'react-reveal';
import classNames from "classnames";

import StockCSS from '../../components/Stock.scss';
import AppCSS from "../../App.scss";
import HomeCSS from "./Home.scss";

import SetMetaTags from "../../components/metas/SetMetaTags";
import WriteUsForm from "./WriteUsForm";
import GoalsSVG from "../../images/home/goals.svg";
import FreeTimeSVG from "../../images/home/technologies.svg";
import HomeJSON from "./Home.json";
import SlideShow from "./SlideShow";

const FreeTimeDescription = (props) => (
    <section className={classNames(StockCSS.blue, StockCSS.skewLeftBottom, StockCSS.skewRightTop)}>
        <div className={StockCSS.stock}>
            <div className={classNames(AppCSS.appResponsive, StockCSS.preface)}>
                <Flip right>
                    <div>
                        <img className={StockCSS.stockImg}
                             src={FreeTimeSVG}
                             alt="Free time"
                             title="Free time"/>
                    </div>
                </Flip>
                <Fade right cascade>
                    <div>
                        <h1 className={AppCSS.c3}>{props.translate("freeTimeDescription1")}</h1>
                        <hr/>
                        <h3 className={AppCSS.c2}>{props.translate("freeTimeDescription2")}</h3>
                    </div>
                </Fade>
            </div>
        </div>
    </section>
);

const WriteUsDescription = (props) => (
    <section className={StockCSS.black}>
        <div className={HomeCSS.writeUsDescriptionBackground}/>
        <div className={StockCSS.stock}>
            <div className={classNames(AppCSS.appResponsive, StockCSS.preface)}>
                <Fade left cascade>
                    <div>
                        <h3 className={AppCSS.c2}>{props.translate("writeUsDescription1")}</h3>
                        <h3 className={AppCSS.c2}>{props.translate("writeUsDescription2")}</h3>
                        <h1 className={AppCSS.c3}>{props.translate("writeUsDescription3")}</h1>
                    </div>
                </Fade>
                <div>
                    <WriteUsForm/>
                </div>
            </div>
        </div>
    </section>
);

const GoalsDescription = (props) => (
    <section className={classNames(StockCSS.white, StockCSS.skewRightTop)}>
        <div className={StockCSS.stock}>
            <div className={classNames(AppCSS.appResponsive, StockCSS.preface)}>
                <Flip right>
                    <div>
                        <img className={StockCSS.stockImg}
                             src={GoalsSVG}
                             alt="Goals"
                             title="Goals"/>
                    </div>
                </Flip>
                <Fade right cascade>
                    <div>
                        <h1 className={AppCSS.c3}>{props.translate("goalsDescription1")}</h1>
                        <h3 className={AppCSS.c2}>{props.translate("goalsDescription2")}</h3>
                        <hr/>
                        <h1 className={AppCSS.c3}>{props.translate("goalsDescription3")}</h1>
                        <h3 className={AppCSS.c2}>{props.translate("goalsDescription4")}</h3>
                    </div>
                </Fade>
            </div>
        </div>
    </section>
);

class Home extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(HomeJSON);
    }

    render() {
        return (
            <span>
                <SetMetaTags title={this.props.translate("home")}
                             description={this.props.translate("homeMetaDescription")}/>
                <SlideShow/>
                <FreeTimeDescription translate={this.props.translate}/>
                <WriteUsDescription translate={this.props.translate}/>
                <GoalsDescription translate={this.props.translate}/>
            </span>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Home);