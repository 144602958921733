import React, {Component, createRef} from "react";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import classNames from "classnames";
import {connect} from "react-redux";
import AppCSS from "../../App.scss";
import InputChecker from "../../controllers/InputChecker";
import {StatusNavigate} from "./Order";
import {addNotice} from "../../components/actions/NoticeActions";
import {api} from "../../controllers/API";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import {InputTitle} from "../../components/inputs/InputTools";

class PaymentOrder extends Component {

    constructor(props) {
        super(props);

        this.ic = new InputChecker();
        this.formRef = createRef();

        this.paymentMethods = [];

        this.state = {
            isError: false,
            isLoading: true,
            paymentIndex: 0
        };

        api.get("/payments").then(response => {
            const newState = {isLoading: false};
            if (response.data.status) {
                newState.isError = false;
                this.paymentMethods = response.data.data.paymentMethods;
            } else {
                newState.isError = true;
                this.props.addNotice(response.data.notice);
            }
            this.setState(prevState => ({...prevState, ...newState}));
        }).catch(error => {
            this.setState(prevState => ({...prevState, isError: true, isLoading: false}));
            console.log(error);
            this.props.addNotice(error.message);
        });
    }

    changePayment = (e) => {
        const selectedIndex = e.target.selectedIndex;
        this.setState(prevState => ({...prevState, paymentIndex: selectedIndex}));
    };

    savePaymentData = () => {

        if (this.paymentMethods.length !== 0) {
            if (this.paymentMethods[this.state.paymentIndex]) {
                this.props.paymentMethod.id = this.paymentMethods[this.state.paymentIndex].id;
                this.props.paymentMethod.name = this.paymentMethods[this.state.paymentIndex].name;
                this.props.paymentMethod.shippingPrice = this.paymentMethods[this.state.paymentIndex].shippingPrice;
                this.props.next();
                return;
            }
        }

        this.ic.setErrorColor(this.formRef.current.elements.name);
        this.ic.setErrorColor(this.formRef.current.elements.shippingPrice);
        this.props.addNotice(this.props.translate("paymentPriceError"));

        if (this.ic.isError()) {
            this.ic.clearError();
        }

    };

    render() {
        return <div>
            {
                this.state.isLoading ?
                    <Loading isText={true}/> : (
                        <div>{
                            this.state.isError ?
                                <Error title={this.props.translate("dontFindPaymentMethods")}/> :
                                <div>
                                    <h1 className={AppCSS.c3}>{this.props.translate("orderPayment")}</h1>
                                    <p className={AppCSS.c1p5}>{this.props.translate("orderPaymentDescription")}</p>
                                    <hr/>
                                    <p>{this.paymentMethods[this.state.paymentIndex].description}</p>
                                    <form onSubmit={(e) => e.preventDefault()} ref={this.formRef}>
                                        <div className={classNames(AppCSS.appResponsive, AppCSS.verticalTop)}>
                                            <div>
                                                <InputTitle title={this.props.translate("paymentMethod")}/>
                                                <select name="name"
                                                        onChange={this.changePayment}
                                                        className={classNames(AppCSS.appInput, AppCSS.c1p5)}>
                                                    {this.paymentMethods.map(paymentMethod =>
                                                        <option key={paymentMethod.id} value={paymentMethod.id}>
                                                            {paymentMethod.name}
                                                        </option>)}
                                                </select>
                                            </div>
                                            <div>
                                                <InputTitle title={this.props.translate("shippingPrice")}/>
                                                <input
                                                    name="shippingPrice"
                                                    className={classNames(AppCSS.appInput, AppCSS.c1p5)}
                                                    disabled={true}
                                                    value={this.paymentMethods[this.state.paymentIndex] ?
                                                        this.paymentMethods[this.state.paymentIndex].shippingPrice + " Ft" :
                                                        this.props.translate("shippingPriceError")}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                        }
                            <StatusNavigate next={!this.state.isError && this.savePaymentData}
                                            previous={this.props.previous}
                                            translate={this.props.translate}/>
                        </div>
                    )
            }
        </div>;
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(PaymentOrder);