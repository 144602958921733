import ShopCSS from "./Shop.scss";
import ProductCSS from "./Product.scss";
import NoProductPictureSVG from "../../images/svg-icons/shop/product.svg";
import classNames from "classnames";
import AppCSS from "../../App.scss";
import StockCSS from "../../components/Stock.scss";
import React, {Component} from "react";
import SetMetaTags, {OGImageJPEGType} from "../../components/metas/SetMetaTags";
import AddProductToCartForm from "./AddProductToCartForm";
import {addTranslation, getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {addNotice} from "../../components/actions/NoticeActions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ErrorProductPictureSVG from "../../images/svg-icons/shop/error-product.svg";
import Loading from "../../components/Loading";
import Error from "../../components/Error";
import ShopJSON from "./Shop.json";
import Photo from "../../components/photo-viewer/Photo";
import {api} from "../../controllers/API";

const ProductItem = (props) => (
    <div className={ShopCSS.shopItem}>
        <Link to={"/shop/product/" + props.product.urlName}>
            <div className={ShopCSS.shopItemImage}
                 style={{
                     background: "url('" +
                         (props.product.coverImage ? props.product.coverImage : NoProductPictureSVG)
                         + "') center center / cover no-repeat"
                 }}>
            </div>
        </Link>
        <div className={ShopCSS.shopItemFlip}>
            <Link to={"/shop/product/" + props.product.urlName}>
                <div className={ShopCSS.shopItemFlipCard}>
                    <figure
                        className={classNames(ShopCSS.shopItemName, AppCSS.c1, StockCSS.white)}>{props.product.name}</figure>

                    <figure className={
                        classNames(ShopCSS.shopItemBack, ShopCSS.shopItemName, AppCSS.c1p5, StockCSS.black)
                    }>
                        {props.product.name}
                    </figure>
                </div>
            </Link>
        </div>
        <div
            className={classNames(ShopCSS.shopItemContent)}>
            <Link to={"/shop/product/" + props.product.urlName}>
                <div className={classNames(StockCSS.emphasize, StockCSS.black, ShopCSS.shopItemDescriptionContent)}>
                    <div>{props.product.description}</div>
                </div>
            </Link>
            <div className={classNames(ShopCSS.shopItemPrice, StockCSS.white)}>
                <div className={ShopCSS.shopItemPriceTitleContent}>{props.translate("price")}</div>
                <div className={ShopCSS.shopItemPriceValueContent}><b>{props.product.unitPrice}</b>Ft/db</div>
                <div className={ShopCSS.shopItemCartContent}>
                    <AddProductToCartForm product={props.product}/>
                </div>
            </div>
        </div>
    </div>
);

export const ProductsList = (props) => (
    <div className={ShopCSS.shopItemsList}>
        {props.products.map(product =>
            <ProductItem key={product.productId} product={product} translate={props.translate}/>
        )}
    </div>
);

const ProductImageList = (props) => (
    <div className={ProductCSS.productImageListContent}>
        <div className={ProductCSS.productImageList}>
            {
                props.images.map((image, i) => <div key={i}>
                    <Photo
                        group={props.group}
                        src={image}
                        alt={props.title}
                        title={props.title}
                    />
                </div>)
            }
        </div>
    </div>
);

class Product extends Component {

    constructor(props) {
        super(props);

        this.props.addTranslation(ShopJSON);

        this.urlName = props.match.params.urlName ? props.match.params.urlName : null;
        this.state = {
            isError: false,
            isLoading: true
        };

        this.product = null;
        this.loadProduct();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.urlName !== this.props.match.params.urlName) {
            this.urlName = this.props.match.params.urlName ? this.props.match.params.urlName : null;
            this.setState(prevState => ({...prevState, isError: false, isLoading: true}));
            this.product = null;
            this.loadProduct();
        }
    }

    loadProduct = () => {

        api.get("/shop/product/" + this.urlName).then(response => {
            const newState = {isLoading: false};
            if (response.data.status) {
                newState.isError = false;
                this.product = response.data.data.product;
            } else {
                newState.isError = true;
                this.props.addNotice(response.data.notice);
            }
            this.setState(prevState => ({...prevState, ...newState}));
        }).catch(error => {
            this.setState(prevState => ({...prevState, isError: true, isLoading: false}));
            console.log(error);
            this.props.addNotice(error.message);
        });
    };

    render() {
        return (
            <span>
                <section className={StockCSS.white}>
                    <div className={StockCSS.stock}>
                    {this.state.isLoading ?
                        <Loading isText={true}/> :
                        (this.state.isError ?
                            <Error imgSrc={ErrorProductPictureSVG} title={this.props.translate("notFoundProduct")}/>
                            :
                            <div>
                                <SetMetaTags
                                    title={this.product.name + " - " + this.product.unitPrice + "Ft / " + this.product.partNumber}
                                    description={this.product.description}
                                    ogSrc={this.product.coverImage ? this.product.coverImage : null}
                                    ogImageType={OGImageJPEGType}/>
                                <div className={ProductCSS.productBox}>
                                    <div className={ProductCSS.productImageContent}>
                                        <div className={ProductCSS.productCoverImageContent}>
                                            <Photo
                                                group={this.product.partNumber}
                                                className={ProductCSS.productCoverImage}
                                                src={this.product.coverImage ? this.product.coverImage : NoProductPictureSVG}
                                                alt={this.product.name}
                                                title={this.product.name}/>
                                        </div>
                                        {this.product.images &&
                                        <ProductImageList images={this.product.images}
                                                          title={this.product.name}
                                                          group={this.product.partNumber}
                                                          translate={this.props.translate}/>
                                        }
                                    </div>
                                    <div className={ProductCSS.productInformationContent}>
                                        <div className={ProductCSS.productData}>
                                            <div className={ProductCSS.productDataTitle}>
                                                <h1
                                                    className={AppCSS.c2}>{this.product.name}</h1>
                                                <hr/>
                                            </div>
                                            <div
                                                className={classNames(ProductCSS.productDataContent, AppCSS.appText, ProductCSS.productInformation)}>
                                                <div>{this.props.translate("partNumber")}</div>
                                                <div>
                                                    <b className={AppCSS.c1p5}>{this.product.partNumber}</b>
                                                </div>
                                                <div>{this.props.translate("inStorage")}</div>
                                                <div>
                                                    <b className={AppCSS.c1p5}>{this.product.storage}</b> db
                                                </div>
                                                <div>{this.props.translate("price")} (br)</div>
                                                <div>
                                                    <span className={AppCSS.c2}>{this.product.unitPrice}</span> Ft/db
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classNames(ProductCSS.productCartContent, AppCSS.appResponsive)}>
                                        <div>
                                            <AddProductToCartForm product={this.product}/>
                                        </div>
                                        <div>
                                            <Link className={classNames(AppCSS.appButton, AppCSS.c1)}
                                                  to={"/shop/products" + (this.product.paths.join("/") !== "" && "/" + this.product.paths.join("/"))}>
                                                {this.props.translate("back")}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className={ProductCSS.productDescriptionContent}>
                                        <div className={ProductCSS.productData}>
                                            <div
                                                className={classNames(ProductCSS.productDataTitle, AppCSS.c1p5)}>
                                                {this.props.translate("description")}
                                                <hr/>
                                            </div>
                                            <div
                                                className={classNames(ProductCSS.productDataContent, AppCSS.appText)}>{this.product.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                    }
                    </div>
                </section>
            </span>
        );
    }

}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice,
        addTranslation
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Product);