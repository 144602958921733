import React from 'react';

import AppCSS from "../App.scss";
import StockCSS from "../components/Stock.scss";
import ErrorSVG from '../images/svg-icons/status/error.svg';

const Error = (props) => {

    const imgSrc = props.imgSrc ? props.imgSrc : ErrorSVG;
    const title = props.title ? props.title : "";

    return (
        <div className={AppCSS.appResponsive}>
            <div>
                <img src={imgSrc} className={StockCSS.stockImg} alt={title}/>
            </div>
            <div>
                <h2 className={AppCSS.c2}>{title}</h2>
                <div>{props.children}</div>
            </div>
        </div>
    );
};

export default Error;