import React, {Component} from "react";
import classNames from "classnames";
import StockCSS from '../../components/Stock.scss';
import AppCSS from "../../App.scss";
import {bindActionCreators} from "redux";
import {addNotice} from "../../components/actions/NoticeActions";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import InputChecker from "../../controllers/InputChecker";
import {api} from "../../controllers/API";

class ForgotPasswordForm extends Component {

    constructor(props) {
        super(props);
        this.ic = new InputChecker();
    }

    update = (e) => {
        e.preventDefault();

        if (this.ic.empty(e.target.elements.email.value)) {
            this.ic.setErrorColor(e.target.elements.email);
            this.props.addNotice(this.props.translate("emptyEmail"));
        }

        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        const formData = new FormData(e.target);

        api.post("/user/forgot-password", formData)
            .then(response => {
                this.props.addNotice(response.data.notice);
            })
            .catch(error => {
                console.log(error);
                this.props.addNotice(error.message);
            });

    };

    render() {

        return (
            <form onSubmit={this.update}>
                <div>
                    <div>
                        <input type="text"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="email"
                               placeholder={this.props.translate("yourEmail")}/>
                    </div>
                    <div>
                        <button
                            className={classNames(AppCSS.appButton, AppCSS.c2)}>{this.props.translate("send")}</button>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(ForgotPasswordForm);