export const ShopReducer = (state = {
    products: [],
    groups: []
}, action) => {
    switch (action.type) {
        case "SET_PRODUCTS":
            return {...state, products: action.payload};

        case "SET_GROUPS":
            return {...state, groups: action.payload};

        default:
            return state;
    }
};