import axios from "axios";
import {getToken, isNotExpToken, setToken} from "../components/actions/UserSessionActions";
import {store} from "../index";

const httpReqAPI = axios.create({
    // baseURL: "https://api." + window.location.host
    baseURL: process.env.NODE_ENV === "production" ?
        "https://" + window.location.host + "/api" :
        "http://" + window.location.host + "/api"
});

httpReqAPI.interceptors.request.use(config => {
    const token = getToken(store.getState().userSession)();
    if (isNotExpToken(store.getState().userSession)() && token) {
        config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
});

httpReqAPI.interceptors.response.use(response => {
    let bearerToken = response.headers.authorization;
    if (bearerToken) {
        store.dispatch(setToken(bearerToken.substr(7, bearerToken.length)));
    }
    return response;
});

export const api = httpReqAPI;