import React, {Component} from "react";
import classNames from "classnames";
import StockCSS from "../../../components/Stock.scss";
import AppCSS from "../../../App.scss";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import InputChecker from "../../../controllers/InputChecker";
import {Fade} from "react-reveal";
import {addNotice} from "../../../components/actions/NoticeActions";

class ForgotPasswordActivation extends Component {

    constructor(props) {
        super(props);

        this.ic = new InputChecker();
    }

    activation = (e) => {
        e.preventDefault();

        if (this.ic.empty(e.target.elements.newPassword.value)) {
            this.ic.setErrorColor(e.target.elements.newPassword);
            this.props.addNotice(this.props.translate("emptyNewPassword"));
        }

        if (this.ic.empty(e.target.elements.newPasswordAgain.value)) {
            this.ic.setErrorColor(e.target.elements.newPasswordAgain);
            this.props.addNotice(this.props.translate("emptyNewPasswordAgain"));
        }

        if (this.ic.isError()) {
            this.props.setSuccess(false);
            this.ic.clearError();
            return;
        }

        const formData = new FormData(e.target);
        formData.append("key", this.props.activationkey);

        axios.post("/api/activation/forgot-password", formData).then(response => {
            this.props.setSuccess(response.data.status);
            this.props.addNotice(response.data.notice);
        }).catch(error => {
            console.log(error);
            this.props.addNotice(error.message);
        });
    };

    render() {
        return (
            <div>
                <h2 className={AppCSS.c3}>{this.props.translate("forgotPasswordActivation")}</h2>
                <form onSubmit={this.activation}>
                    <Fade right>
                        <div>
                            <input className={classNames(AppCSS.appInput, AppCSS.c1p5, StockCSS.white)} type="password"
                                   name="newPassword" placeholder={this.props.translate("yourNewPassword")}/>
                        </div>
                        <div>
                            <input className={classNames(AppCSS.appInput, AppCSS.c1p5, StockCSS.white)} type="password"
                                   name="newPasswordAgain" placeholder={this.props.translate("yourNewPasswordAgain")}/>
                        </div>
                        <button
                            className={classNames(AppCSS.appButton, AppCSS.c2)}>{this.props.translate("save")}</button>
                    </Fade>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(ForgotPasswordActivation);