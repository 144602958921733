let isEnableCookies = false;

export default class CookieManager {

    setEnable = isEnable => {
        isEnableCookies = isEnable;
    };

    set = (name, value, expires) => {
        if (isEnableCookies) {
            if (typeof name === "string") {

                if (typeof expires !== "number") {
                    expires = 30;
                }
                let d = new Date();
                d.setTime(d.getTime() + (expires * 24 * 60 * 60 * 1000));
                let expiresStr = "expires=" + d.toUTCString();
                document.cookie = name + "=" + value.toString() + ";" + expiresStr + ";path=/";
            }
        }
    };

    get = (name) => {
        if (typeof name === "string") {
            let nameStr = name + "=";
            let ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(nameStr) === 0) {
                    return c.substring(nameStr.length, c.length);
                }
            }
        }
        return null;
    };
}