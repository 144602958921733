import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {getTranslate} from 'react-localize-redux';
import {connect} from 'react-redux';

import Footer from '../components/footer/Footer';

import Error from '../components/Error';
import Error404SVG from '../images/svg-icons/status/error-404.svg';
import Notice from "../components/notice/Notice";
import ScrollTop from "../components/ScrollTop";
import PhotoViewer from "../components/photo-viewer/PhotoViewer";

import StockCSS from "../components/Stock.scss";
import CookieBar from "../components/CookieBar";
import MetaTags from "../components/metas/MetaTags";
import DocViewer from "../components/DocViewer";
import classNames from "classnames";
import AppCSS from "../App.scss";
import Menu, {MenuItem} from "../components/header/Menu";
import SocialNetworks from "../components/header/SocialNetworks";
import Home from "./pages/Home";
import Contact from "./pages/ContactUs";
import UserBlock from "./user/UserBlock";
import Activation from "./pages/activation/Activation";
import CartBlock from "./cart/CartBlock";
import Shop from "./shop/Shop";
import Product from "./shop/Products";
import APIProgressBar from "../components/APIProgressBar";
import Order from "./order/Order";

class App extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <APIProgressBar/>
                <MetaTags/>
                <BrowserRouter>
                    <div>
                        <header className={classNames(AppCSS.appResponsive)}>
                            <div>
                                <Menu>
                                    <MenuItem to="/" title={this.props.translate("home")}/>
                                    <MenuItem to="/contact-us" title={this.props.translate("contactUs")}/>
                                    <MenuItem to="/shop/products" title={this.props.translate("ourProducts")}/>
                                </Menu>
                            </div>
                            <div>
                                <SocialNetworks/>
                            </div>
                        </header>
                        <Switch>
                            <Route path="/" component={Home} exact/>
                            <Route path="/contact-us" component={Contact}/>
                            <Route path="/activation/:mode/:key" component={Activation}/>
                            <Route path="/shop/product/:urlName" component={Product}/>
                            <Route path="/shop/:selected(products|search)/:path*" component={Shop}/>
                            <Route path="/order" component={Order}/>
                            <Route>
                                <section className={StockCSS.white}>
                                    <Error title="Error 404"
                                           imgSrc={Error404SVG}>
                                        <h2>{this.props.translate("thePageNotFound")}</h2>
                                    </Error>
                                </section>
                            </Route>
                        </Switch>
                        <UserBlock/>
                        <CartBlock/>
                    </div>
                </BrowserRouter>
                <Footer/>
                {/*<Language/>*/}
                <ScrollTop/>
                <Notice/>
                <PhotoViewer/>
                <DocViewer/>
                <CookieBar/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

export default connect(mapStateToProps, null)(App);
