import React, {Component} from "react";
import classNames from "classnames";
import StockCSS from '../../components/Stock.scss';
import AppCSS from "../../App.scss";
import {bindActionCreators} from "redux";
import {addNotice} from "../../components/actions/NoticeActions";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import InputChecker from "../../controllers/InputChecker";
import {getID} from "../../components/actions/UserSessionActions";
import {api} from "../../controllers/API";

class UpdatePasswordForm extends Component {

    constructor(props) {
        super(props);
        this.ic = new InputChecker();
    }

    update = (e) => {
        e.preventDefault();

        if (this.ic.empty(e.target.elements.password.value)) {
            this.ic.setErrorColor(e.target.elements.password);
            this.props.addNotice(this.props.translate("emptyPassword"));
        }

        if (this.ic.empty(e.target.elements.newPassword.value)) {
            this.ic.setErrorColor(e.target.elements.newPassword);
            this.props.addNotice(this.props.translate("emptyNewPassword"));
        }

        if (this.ic.empty(e.target.elements.newPasswordAgain.value)) {
            this.ic.setErrorColor(e.target.elements.newPasswordAgain);
            this.props.addNotice(this.props.translate("emptyNewPasswordAgain"));
        }

        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        const formData = new FormData(e.target);

        api.post("/user/" + this.props.getID() + "/password", formData)
            .then(response => {
                this.props.addNotice(response.data.notice);
            })
            .catch(error => {
                console.log(error);
                this.props.addNotice(error.message);
            });

    };

    render() {

        return (
            <form onSubmit={this.update}>
                <div>
                    <div>
                        <input type="password"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="password"
                               placeholder={this.props.translate("yourPassword")}/>
                    </div>
                    <div>
                        <input type="password"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="newPassword"
                               placeholder={this.props.translate("yourNewPassword")}/>
                    </div>
                    <div>
                        <input type="password"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="newPasswordAgain"
                               placeholder={this.props.translate("yourNewPasswordAgain")}/>
                    </div>
                    <div>
                        <button
                            className={classNames(AppCSS.appButton, AppCSS.c2)}>{this.props.translate("save")}</button>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getID: getID(state.userSession)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice,
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(UpdatePasswordForm);