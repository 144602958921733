import React, {Component} from "react";
import {connect} from "react-redux";
import {getTranslate} from "react-localize-redux";
import classNames from "classnames";

import AppCSS from "../App.scss";
import PopUpCSS from "./PopUp.scss";
import StockCSS from "./Stock.scss";

export const PopUpFrame = (props) => (
    <div className={classNames(PopUpCSS.popUp, (props.visible ? PopUpCSS.visible : null))}>
        <div className={classNames(PopUpCSS.popUpFrame)}>
            {props.children}
        </div>
    </div>
);

export const PopUpContent = (props) => (
    <div className={classNames(PopUpCSS.popUpContent, StockCSS.white)}>
        {props.children}
    </div>
);

export const PopUpPadding = (props) => (
    <div className={PopUpCSS.popUpPadding}>
        {props.children}
    </div>
);

class PopUp extends Component {

    render() {
        return (
            <PopUpFrame visible={this.props.visible}>
                {this.props.isCloseButton &&
                <div title={this.props.translate("close")}
                     className={classNames(AppCSS.appCircleButton, PopUpCSS.popUpClose)}
                     onClick={this.props.onHide}>
                    <i className={AppCSS.materialIcons}>close</i>
                </div>
                }
                {this.props.children}
            </PopUpFrame>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

export default connect(mapStateToProps, null)(PopUp);