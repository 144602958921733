import React, {Component} from "react";
import CartContentCSS from "./CartContent.scss";
import CartItemCSS from "./CartItem.scss";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import CartItem from "./CartItem";
import {getCartContent, getItemsCount, getLastModified, updateCartInLocaleStorage} from "../actions/CartActions";
import CartSumPrice from "./CartSumPrice";

const CartListTitle = (props) => (
    <div className={CartContentCSS.cartListTitle}>
        <div className={CartItemCSS.cartItemPiece}>{props.translate("piece")}</div>
        <div className={CartItemCSS.cartItemUnitPrice}>{props.translate("unitPrice")}</div>
        <div className={CartItemCSS.cartItemSumPrice}>{props.translate("sumPrice")}</div>
    </div>
);

const CartList = (props) => (
    <div className={CartContentCSS.cartList}>
        <div>
            {Object.keys(props.items).map(key => <CartItem key={key} {...props.items[key]}/>)}
        </div>
    </div>
);

class CartContent extends Component {

    constructor(props) {
        super(props);

        this.lastSaved = this.props.getLastModified();

        setInterval(() => {
            if (this.props.getLastModified() > this.lastSaved) {
                updateCartInLocaleStorage(this.props.getCartContent());
                this.lastSaved = this.props.getLastModified();
            }
        }, 10000);
    }

    render() {

        if (this.props.getItemsCount()) {
            return (<span>
                            <CartListTitle translate={this.props.translate}/>
                            <CartList items={this.props.getCartContent()}/>
                            <hr/>
                            <CartSumPrice/>
                        </span>);
        } else {
            return (<p>{this.props.translate("theCartIsEmpty")}</p>);
        }
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getCartContent: getCartContent(state.cart),
        getItemsCount: getItemsCount(state.cart),
        getLastModified: getLastModified(state.cart)
    };
};

export default connect(mapStateToProps, null)(CartContent);
