import React from "react";
import {connect} from "react-redux";
import {MetaTags as ReactMetaTags} from "react-meta-tags";
import {getCompanyData} from "../actions/CompanyActions";

export const OGImagePNGType = "image/png";
export const OGImageJPEGType = "image/jpeg";

const SetMetaTags = (props) => {

    const title = (props.title ? props.title + " - " : "") + props.getCompanyData().getName();
    const description = props.description ?
        props.description.length > 100 ? props.description.substring(0, 100) + "..." : props.description
        : "";
    const ogSrc = props.ogSrc ? (window.location.protocol.indexOf('s') === 4 ?
        props.ogSrc.slice(9, props.ogSrc.length)
        :
        props.ogSrc.slice(8, props.ogSrc.length)) : null;

    return (
        <ReactMetaTags>
            <meta name='description' content={description}/>

            <meta property='og:type' content={props.ogType ? props.ogType : "article"}/>
            <meta property='og:url'
                  content={props.ogURL ? props.ogURL : window.location.origin}/>
            <meta property='og:title'
                  content={props.ogTitle ? props.ogTitle : title}/>
            <meta property='og:description'
                  content={props.ogDescription ?
                      props.ogDescription.length > 100 ? props.ogDescription.substring(0, 100) + "..." : props.ogDescription
                      : description}/>
            <meta property={"og:image"}
                  content={"http://" + (ogSrc ? ogSrc : window.location.hostname + process.env.PUBLIC_URL + "/banner.png")}/>
            <meta property={"og:image:secure_url"}
                  content={"https://" + (ogSrc ? ogSrc : window.location.hostname + process.env.PUBLIC_URL + "/banner.png")}/>
            <meta property='og:image:type' content={props.ogImageType ? props.ogImageType : OGImagePNGType}/>

            <meta name='DC.description' content={description}/>
            <meta name="DC.title"
                  content={title}/>
            <title>{title}</title>
        </ReactMetaTags>
    );
};

const mapStateToProps = state => {
    return {
        getCompanyData: getCompanyData(state.company)
    };
};

export default connect(mapStateToProps)(SetMetaTags);