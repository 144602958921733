export const setName = (name) => (
    {type: "SET_NAME", payload: name}
);

export const setRegisteredName = (registeredName) => (
    {type: "SET_REGISTERED_NAME", payload: registeredName}
);

export const setZipCode = (zipCode) => (
    {type: "SET_ZIP_CODE", payload: zipCode}
);

export const setCity = (city) => (
    {type: "SET_CITY", payload: city}
);

export const setStreet = (street) => (
    {type: "SET_STREET", payload: street}
);

export const setTaxNumber = (taxNumber) => (
    {type: "SET_TAX_NUMBER", payload: taxNumber}
);

export const setRegistryNumber = (registryNumber) => (
    {type: "SET_REGISTRY_NUMBER", payload: registryNumber}
);

export const getCompanyData = (state) => (
    () => (
        {
            getName: () => (state.name),
            getRegisteredName: () => (state.registeredName),
            getZipCode: () => (state.zipCode),
            getCity: () => (state.city),
            getRegistryNumber: () => (state.registryNumber),
            getStreet: () => (state.street),
            getTaxNumber: () => (state.taxNumber)
        }
    )
);