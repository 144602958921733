import React, {Component} from 'react';
import classNames from "classnames";

import AppCSS from "../App.scss";
import CookieBarCSS from "./CookieBar.scss";
import {addTranslation, getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import CookieManager from "../controllers/CookieManager";
import CookieJSON from "../languages/Cookie";
import {bindActionCreators} from "redux";

class CookieBar extends Component {
    constructor(props) {
        super(props);

        this.props.addTranslation(CookieJSON);
        this.cookieManager = new CookieManager();

        let isEnable;
        const isCookie = this.cookieManager.get("isCookie");
        if (isCookie) {
            isEnable = !!JSON.parse(isCookie);
            if (isEnable === true) {
                this.cookieManager.setEnable(true);
            }
        } else {
            isEnable = false;
        }

        this.state = {isEnable};
    }

    gotIt = () => {
        this.cookieManager.setEnable(true);
        this.cookieManager.set("isCookie", true);
        this.setState({isEnable: true});
    };

    render() {
        return (
            <div className={classNames(CookieBarCSS.cookieBar, !this.state.isEnable ? CookieBarCSS.visible : null)}>
                <div className={CookieBarCSS.cookieBarDescription}>
                    {this.props.translate("cookieDescription")}
                    <a href="https://cookiesandyou.com/"
                       target="_blank"
                       rel="noreferrer noopener"
                       className={CookieBarCSS.cookieBarLearnMore}>
                        {this.props.translate("learnMore")}
                    </a>
                </div>
                <div>
                    <button className={classNames(AppCSS.c1p5, AppCSS.appButton)}
                            onClick={this.gotIt}>{this.props.translate("iAccept")}</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(CookieBar);