import React, {Component, createRef} from "react";
import CartItemCSS from "./CartItem.scss";
import {QuickBar, QuickButton} from "../../components/QuickBar";
import SavePieceSVG from "../../images/svg-icons/shop/save-piece.svg";
import RemoveFromCartSVG from "../../images/svg-icons/shop/remove-from-cart.svg";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import AppCSS from "../../App.scss";
import classNames from "classnames";
import StockCSS from '../../components/Stock.scss';
import {deleteItem, isEnableCart, updatePiece} from "../actions/CartActions";
import {addNotice} from "../../components/actions/NoticeActions";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import {Link} from "react-router-dom";

class CartItem extends Component {

    constructor(props) {
        super(props);

        this.piece = this.props.piece;
        this.cartItemContentRef = createRef();
        this.pieceInputRef = createRef();

        this.state = {
            sumPrice: this.piece * this.props.unitPrice,
            confirmPopUpVisible: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.piece !== this.props.piece) {
            this.pieceInputRef.current.value = this.props.piece;
            this.cartItemContentRef.current.classList.remove(CartItemCSS.cartItemDontSave);
            this.calcSumPrice(this.props.piece);
        }
    }

    savePiece = () => {
        if (this.props.isEnableCart()) {
            if (this.piece > 0) {
                this.props.updatePiece(this.props.productId, this.piece);
                this.props.addNotice(this.props.translate("successfullySavedTheNumberOfPiece"));
            } else {
                this.props.addNotice(this.props.translate("incorrectPiece"));
            }
        } else {
            this.restorePiece();
            this.props.addNotice(this.props.translate("theCartIsLocked"));
        }
    };

    removeFromCart = () => {
        if (this.props.isEnableCart()) {
            this.props.deleteItem(this.props.productId);
            this.props.addNotice(this.props.translate("successfullyRemovedFromCart"));
        } else {
            this.props.addNotice(this.props.translate("theCartIsLocked"));
        }

        this.setState(prevState => ({...prevState, confirmPopUpVisible: false}));
    };

    calcSumPrice = (piece) => {
        this.setState(prevState => ({...prevState, sumPrice: piece * this.props.unitPrice}));
    };

    changePiece = (e) => {
        const pieceParse = parseInt(e.target.value);
        if (!isNaN(pieceParse)) {
            this.piece = pieceParse;
            this.calcSumPrice(this.piece);
        }

        if (this.props.piece !== this.piece) {
            this.cartItemContentRef.current.classList.add(CartItemCSS.cartItemDontSave);
        } else {
            this.cartItemContentRef.current.classList.remove(CartItemCSS.cartItemDontSave);
        }
    };

    restorePiece = () => {
        this.pieceInputRef.current.value = this.props.piece;
        this.cartItemContentRef.current.classList.remove(CartItemCSS.cartItemDontSave);
    };

    render() {
        return (
            <div className={CartItemCSS.cartItem}>
                <Link to={"/shop/product/" + this.props.urlName} className={StockCSS.blue}>
                    <div className={classNames(CartItemCSS.cartItemName, StockCSS.blue)}>
                        {this.props.productName}
                    </div>
                </Link>
                <div className={CartItemCSS.cartItemContent} ref={this.cartItemContentRef}>
                    <div className={CartItemCSS.cartItemPiece}>
                        <input type="number"
                               className={classNames(AppCSS.appInput, StockCSS.white)}
                               defaultValue={this.piece}
                               ref={this.pieceInputRef}
                               onChange={this.changePiece}
                               min={1}
                               step={1}/>
                        <span>db</span>
                    </div>
                    <div className={CartItemCSS.cartItemUnitPrice}>{this.props.unitPrice} Ft</div>
                    <div className={CartItemCSS.cartItemSumPrice}>{this.state.sumPrice} Ft</div>
                    <div className={CartItemCSS.cartItemTools}>
                        <QuickBar>
                            <QuickButton title={this.props.translate("savePiece")}
                                         onClick={this.savePiece}
                                         imgSrc={SavePieceSVG}/>
                            <QuickButton title={this.props.translate("removeFromCart")}
                                         onClick={() => (this.setState(prevState => ({
                                             ...prevState,
                                             confirmPopUpVisible: true
                                         })))}
                                         imgSrc={RemoveFromCartSVG}/>
                        </QuickBar>
                    </div>
                </div>
                <ConfirmPopUp visible={this.state.confirmPopUpVisible}
                              question={this.props.translate("questionBeforeRemoveFromCart", {productName: this.props.productName})}
                              noEvent={() => (this.setState(prevState => ({...prevState, confirmPopUpVisible: false})))}
                              yesEvent={this.removeFromCart}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        isEnableCart: isEnableCart(state.cart)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        deleteItem,
        addNotice,
        updatePiece
    }, dispatch);
};


export default connect(mapStateToProps, matchDispatchToProps)(CartItem);