import React, {Component} from "react";
import classNames from "classnames";
import StockCSS from '../../components/Stock.scss';
import AppCSS from "../../App.scss";
import {bindActionCreators} from "redux";
import {addNotice} from "../../components/actions/NoticeActions";
import {getTranslate} from "react-localize-redux";
import {connect} from "react-redux";
import InputChecker from "../../controllers/InputChecker";
import {getBillingData, setBillingData} from "../actions/BillingActions";
import {getID} from "../../components/actions/UserSessionActions";
import {api} from "../../controllers/API";

class UpdateBillingForm extends Component {

    constructor(props) {
        super(props);
        this.ic = new InputChecker();
    }

    update = (e) => {
        e.preventDefault();

        if (this.ic.empty(e.target.elements.billingName.value)) {
            this.ic.setErrorColor(e.target.elements.billingName);
            this.props.addNotice(this.props.translate("emptyName"));
        }

        if (this.ic.empty(e.target.elements.billingZipCode.value)) {
            this.ic.setErrorColor(e.target.elements.billingZipCode);
            this.props.addNotice(this.props.translate("emptyZipCode"));
        }

        if (this.ic.empty(e.target.elements.billingCity.value)) {
            this.ic.setErrorColor(e.target.elements.billingCity);
            this.props.addNotice(this.props.translate("emptyCity"));
        }

        if (this.ic.empty(e.target.elements.billingStreet.value)) {
            this.ic.setErrorColor(e.target.elements.billingStreet);
            this.props.addNotice(this.props.translate("emptyStreet"));
        }

        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        const formData = new FormData(e.target);

        api.post("/user/" + this.props.getID() + "/billing", formData)
            .then(response => {
                if (response.data.status) {
                    this.props.setBillingData(response.data.data.billing);
                }
                this.props.addNotice(response.data.notice);
            })
            .catch(error => {
                console.log(error);
                this.props.addNotice(error.message);
            });

    };

    render() {

        return (
            <form onSubmit={this.update}>
                <div>
                    <div>
                        <input type="text"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="billingName"
                               defaultValue={this.props.getBillingData().getName()}
                               placeholder={this.props.translate("billingName")}/>
                    </div>
                    <div>
                        <input type="text"
                               maxLength="4"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="billingZipCode"
                               defaultValue={this.props.getBillingData().getZipCode()}
                               placeholder={this.props.translate("zipCode")}/>
                    </div>
                    <div>
                        <input type="text"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="billingCity"
                               defaultValue={this.props.getBillingData().getCity()}
                               placeholder={this.props.translate("city")}/>
                    </div>
                    <div>
                        <input type="text"
                               className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                               name="billingStreet"
                               defaultValue={this.props.getBillingData().getStreet()}
                               placeholder={this.props.translate("street")}/>
                    </div>
                    <div>
                        <button
                            className={classNames(AppCSS.appButton, AppCSS.c2)}>{this.props.translate("save")}</button>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getID: getID(state.userSession),
        getBillingData: getBillingData(state.billing),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice,
        setBillingData
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(UpdateBillingForm);