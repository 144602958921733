import React, {Component} from "react";
import classNames from "classnames";

import UserBlockCSS from './UserBlock.scss';
import AppCSS from "../../App.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import UserSVG from "../../images/svg-icons/user/user.svg";
import {addTranslation, getTranslate} from "react-localize-redux";
import ProfileBlock from "./ProfileBlock";
import SignInBlock from "./SignInBlock";
import UserJSON from "./User.json";
import {isExpToken} from "../../components/actions/UserSessionActions";

class UserBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.props.addTranslation(UserJSON);
    }

    render() {
        return (
            <div className={classNames(UserBlockCSS.userBlock, this.state.visible ? UserBlockCSS.visible : null)}>
                <span onClick={() => this.setState(prevState => ({...prevState, visible: !prevState.visible}))}
                      className={classNames(AppCSS.appCircleButton, AppCSS.appImgButton, AppCSS.appToolbarItem, UserBlockCSS.userButton)}
                      title={this.props.translate("profile")}>
                <img src={UserSVG} title={this.props.translate("profile")} alt={this.props.translate("profile")}/>
                </span>
                {this.props.isExpToken() ? <SignInBlock/> : <ProfileBlock/>}
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        isExpToken: isExpToken(state.userSession),
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation
    }, dispatch);
};


export default connect(mapStateToProps, matchDispatchToProps)(UserBlock);