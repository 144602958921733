import React, {Component} from "react";
import {addNotice} from "../../components/actions/NoticeActions";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import classNames from "classnames";
import AppCSS from "../../App.scss";
import ShopSearchCSS from "./ShopSearch.scss";
import InputChecker from "../../controllers/InputChecker";

class ShopSearch extends Component {

    constructor(props) {
        super(props);

        this.ic = new InputChecker();
    }

    search = (e) => {
        e.preventDefault();

        if (this.ic.empty(e.target.elements.search.value)) {
            this.ic.setErrorColor(e.target.elements.search);
            this.props.addNotice(this.props.translate("emptySearch"));
        }

        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        this.props.history.push("/shop/search/" + e.target.elements.search.value);
    };

    render() {

        return (
            <form onSubmit={this.search}>
                <div className={classNames(AppCSS.appResponsive, ShopSearchCSS.searchBlock)}>
                    <div>
                        <input type="search"
                               name="search"
                               placeholder={this.props.translate("search")}
                               className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                    </div>
                    <div>
                        <button
                            className={classNames(AppCSS.appButton, AppCSS.c1p5)}>{this.props.translate("search")}</button>
                    </div>
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice: addNotice,
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(ShopSearch);