import React, {Component, createRef} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import classNames from "classnames";

import AppCSS from "../../App.scss";
import NoticeCSS from './Notice.scss';

import {dropNotice} from "../actions/NoticeActions";
import {getTranslate} from "react-localize-redux";

const TIMER_STEP = 500;

const Messages = props => {
    return props.messages.map((message, i) => <div key={i} className={NoticeCSS.noticeData}>{message}</div>);
};

const CloseButton = props => (
    <div
        className={classNames(NoticeCSS.noticeCloseButton, AppCSS.appCircleButton, (props.cls ? NoticeCSS.visible : null))}
        onClick={props.closeHandle}
        title={props.title}>
        <i className={AppCSS.materialIcons}>close</i>
    </div>
);

class Notice extends Component {

    constructor(props) {
        super(props);

        this.timer = null;
        this.time = 0;
        this.startTime = 0;
        this.messages = [];
        this.dataBlockRef = createRef();
        this.timeLineRef = createRef();
    }

    timerStop = () => {
        this.startTime = this.time = 0;
        this.resetTimeLine();

        this.props.dropNotice();
        clearInterval(this.timer);
        this.timer = null;
    };

    timerStart = () => {
        if (this.timer === null) {
            this.startTimeLine();

            this.timer = setInterval(() => {
                this.calcTimeLine();
                if (this.time > 0) {
                    this.time -= TIMER_STEP;
                } else {
                    this.timerStop();
                }
            }, TIMER_STEP);
        }
    };

    timerState = (time) => {
        if (time !== 0) {
            if (this.timer === null) {
                this.startTime = this.time = time;
                this.timerStart();
            } else {
                this.time += time - this.startTime;
                this.startTime = time;
            }
        }
    };

    resetTimeLine = () => {
        this.timeLineRef.current.classList.remove(NoticeCSS.startNoticeTimeLine);
    };

    startTimeLine = () => {
        this.timeLineRef.current.style.width = "100%";
        this.timeLineRef.current.classList.add(NoticeCSS.startNoticeTimeLine);
    };

    calcTimeLine = () => {
        let percent = (this.time - TIMER_STEP) / this.startTime * 100;
        this.timeLineRef.current.style.width = (percent > 0 ? percent : 0) + "%";
    };

    componentDidMount() {
        this.dataBlockRef.current.style.maxHeight = window.innerHeight - 130 + "px";
    }

    componentDidUpdate() {
        this.timerState(this.props.notice.time);
        if (this.props.notice.messages.length) {
            this.messages = this.props.notice.messages;
        }
    }

    render() {
        return (
            <div
                className={classNames(NoticeCSS.noticeBlock, (this.props.notice.messages.length ? NoticeCSS.visible : null))}>
                <div className={NoticeCSS.noticeBlockContent}>
                    <CloseButton closeHandle={this.timerStop}
                                 cls={this.props.notice.cls}
                                 title={this.props.translate("close")}/>
                    <div className={NoticeCSS.noticeTop}>
                        <div ref={this.timeLineRef} className={NoticeCSS.noticeTimeLine}/>
                    </div>
                    <div className={classNames(NoticeCSS.noticeBlockData, AppCSS.c1)}
                         ref={this.dataBlockRef}>
                        <Messages
                            messages={this.props.notice.messages.length ? this.props.notice.messages : this.messages}/>
                    </div>
                    <div className={NoticeCSS.noticeBottom}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        notice: state.notice,
        translate: getTranslate(state.localize),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        dropNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Notice);