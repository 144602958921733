export const deleteAllItems = () => (
    {type: "DELETE_ITEMS_FROM_CART", payload: {key: null}}
);

export const deleteItem = (key) => (
    {type: "DELETE_ITEMS_FROM_CART", payload: {key}}
);

export const updatePiece = (key, piece) => (
    {type: "UPDATE_CART_ITEM", payload: {key, piece}}
);

export const addPiece = (key, item) => (
    {type: "ADD_CART_ITEM", payload: {key, item}}
);

export const getItemsCount = (state) => (
    () => (Object.keys(state.cart).length)
);

export const getCartContent = (state) => (
    () => (state.cart)
);

export const getLastModified = (state) => (
    () => (state.lastModified)
);

export const updateCartInLocaleStorage = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
};

export const loadCartFromLocalStorage = () => {
    const cart = localStorage.getItem("cart");
    if (cart) {
        return JSON.parse(cart);
    }
    return {};
};

export const setEnableCart = (isEnable) => (
    {type: "SET_CART_ENABLE", payload: {isEnable}}
);

export const isEnableCart = (state) => (
    () => (state.isEnable)
);