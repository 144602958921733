import React, {Component} from "react";
import classNames from "classnames";

import StockCSS from '../../components/Stock.scss';
import UserBlockCSS from './UserBlock.scss';
import AppCSS from "../../App.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {QuickBar, QuickButton} from "../../components/QuickBar";

import UpdatePasswordSVG from "../../images/svg-icons/user/update-password.svg";
import UpdateShippingSVG from "../../images/svg-icons/user/update-shipping.svg";
import UpdateBillingSVG from "../../images/svg-icons/user/update-billing.svg";
import UpdateProfileSVG from "../../images/svg-icons/user/update-profile.svg";
import SignOutForm from "./SignOutForm";
import PopUp, {PopUpContent, PopUpPadding} from "../../components/PopUp";
import PopUpCSS from "../../components/PopUp.scss";
import UpdatePasswordForm from "./UpdatePasswordForm";
import {addNotice} from "../../components/actions/NoticeActions";
import {addTranslation, getTranslate} from "react-localize-redux";
import {getProfileData, setProfileData} from "../actions/ProfileActions";
import UpdateShippingForm from "./UpdateShippingForm";
import UpdateBillingForm from "./UpdateBillingForm";
import UpdateProfileForm from "./UpdateProfileForm";
import {setShippingData} from "../actions/ShippingActions";
import {setBillingData} from "../actions/BillingActions";
import dateformat from "dateformat";
import {getID} from "../../components/actions/UserSessionActions";
import {api} from "../../controllers/API";

const UpdateShippingAndBillingContent = (props) => (
    <PopUpContent>
        <PopUpPadding>
            <div className={classNames(AppCSS.appResponsive, AppCSS.verticalTop)}>
                <div>
                    <h1 className={classNames(PopUpCSS.popupTitle, StockCSS.blue, StockCSS.emphasize, AppCSS.c2)}>{props.translate("changeShippingData")}</h1>
                    <p>{props.translate("changeShippingDataDescription")}</p>
                    <UpdateShippingForm/>
                </div>
                <div>
                    <h1 className={classNames(PopUpCSS.popupTitle, StockCSS.blue, StockCSS.emphasize, AppCSS.c2)}>{props.translate("changeBillingData")}</h1>
                    <p>{props.translate("changeBillingDataDescription")}</p>
                    <UpdateBillingForm/>
                </div>
            </div>
        </PopUpPadding>
    </PopUpContent>
);

const UpdateProfileAndPasswordContent = (props) => (
    <PopUpContent>
        <PopUpPadding>
            <div className={classNames(AppCSS.appResponsive, AppCSS.verticalTop)}>
                <div>
                    <h1 className={classNames(PopUpCSS.popupTitle, StockCSS.blue, StockCSS.emphasize, AppCSS.c2)}>{props.translate("changeProfileData")}</h1>
                    <p>{props.translate("changeProfileDataDescription")}</p>
                    <UpdateProfileForm/>
                </div>
                <div>
                    <h1 className={classNames(PopUpCSS.popupTitle, StockCSS.blue, StockCSS.emphasize, AppCSS.c2)}>{props.translate("changePassword")}</h1>
                    <p>{props.translate("changePasswordDescription")}</p>
                    <UpdatePasswordForm/>
                </div>
            </div>
        </PopUpPadding>
    </PopUpContent>
);

const ProfilePopUpsContent = (props) => {
    switch (props.selected) {
        case "shippingAndBilling":
            return <UpdateShippingAndBillingContent translate={props.translate}/>;
        case "profileAndPassword":
            return <UpdateProfileAndPasswordContent translate={props.translate}/>;
        default:
            return null;
    }
};

class LoggedUserBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            selected: null,
        };

        api.get("/user/" + this.props.getID() + "/profile").then(response => {
            this.props.setProfileData(response.data.data.profile);
        }).catch(error => {
            console.log(error);
            this.props.addNotice(error.message);
        });

        api.get("/user/" + this.props.getID() + "/shipping").then(response => {
            this.props.setShippingData(response.data.data.shipping);
        }).catch(error => {
            console.log(error);
            this.props.addNotice(error.message);
        });

        api.get("/user/" + this.props.getID() + "/billing").then(response => {
            this.props.setBillingData(response.data.data.billing);
        }).catch(error => {
            console.log(error);
            this.props.addNotice(error.message);
        });

    }

    render() {
        return (
            <span>
                <div className={classNames(UserBlockCSS.userBlockContent, StockCSS.white)}>
                    <p>{this.props.translate("haveNiceDay", {firstName: this.props.getProfileData().getFirstName()})}</p>
                    <p>{this.props.translate("lastSignIn", {
                        lastSignIn: dateformat(this.props.getProfileData().getLastSignIn(), "yyyy. mm. dd. H:MM:ss")
                    })}</p>
                    <div>
                        <div className={AppCSS.appResponsive}>
                            <div>
                                <QuickBar>
                                    <QuickButton title={this.props.translate("changeProfileData")}
                                                 onClick={() => this.setState(prevState => ({
                                                     ...prevState,
                                                     visible: true,
                                                     selected: "profileAndPassword"
                                                 }))}
                                                 imgSrc={UpdateProfileSVG}/>
                                    <QuickButton title={this.props.translate("changePassword")}
                                                 onClick={() => this.setState(prevState => ({
                                                     ...prevState,
                                                     visible: true,
                                                     selected: "profileAndPassword"
                                                 }))}
                                                 imgSrc={UpdatePasswordSVG}/>
                                    <QuickButton title={this.props.translate("changeShippingData")}
                                                 onClick={() => this.setState(prevState => ({
                                                     ...prevState,
                                                     visible: true,
                                                     selected: "shippingAndBilling"
                                                 }))}
                                                 imgSrc={UpdateShippingSVG}/>
                                    <QuickButton title={this.props.translate("changeBillingData")}
                                                 onClick={() => this.setState(prevState => ({
                                                     ...prevState,
                                                     visible: true,
                                                     selected: "shippingAndBilling"
                                                 }))}
                                                 imgSrc={UpdateBillingSVG}/>

                                </QuickBar>
                            </div>
                            <div>
                                <SignOutForm/>
                            </div>
                        </div>
                    </div>
                </div>
                <PopUp visible={this.state.visible}
                       onHide={() => this.setState(prevState => ({
                           ...prevState,
                           visible: false
                       }))}
                       isCloseButton={true}>
                    <ProfilePopUpsContent selected={this.state.selected} translate={this.props.translate}/>
                </PopUp>
            </span>
        );
    }

}

const mapStateToProps = state => {
    return {
        getProfileData: getProfileData(state.profile),
        translate: getTranslate(state.localize),
        getID: getID(state.userSession)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation,
        addNotice,
        setProfileData,
        setShippingData,
        setBillingData

    }, dispatch);
};


export default connect(mapStateToProps, matchDispatchToProps)(LoggedUserBlock);