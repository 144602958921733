import React, {Component} from "react";
import OrderJSON from "./Order.json";
import SetMetaTags from "../../components/metas/SetMetaTags";
import {addTranslation, getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import StockCSS from "../../components/Stock.scss";
import ShippingOrder from "./ShippingOrder";
import classNames from "classnames";
import AppCSS from "../../App.scss";
import OrderCSS from "./Order.scss";
import BillingOrder from "./BillingOrder";
import {getShippingData} from "../actions/ShippingActions";
import {getProfileData} from "../actions/ProfileActions";
import {getBillingData} from "../actions/BillingActions";
import CartOrder from "./CartOrder";
import PaymentOrder from "./PaymentOrder";
import SummaryOrder from "./SummaryOrder";
import {setEnableCart} from "../actions/CartActions";
import SucceededOrder from "./SucceededOrder";

const StatusSelector = (props) => {

    switch (props.status) {
        case 1:
            return <ShippingOrder profile={props.profile} shipping={props.shipping} next={props.next}/>;
        case 2:
            return <BillingOrder billing={props.billing} next={props.next} previous={props.previous}/>;
        case 3:
            return <CartOrder next={props.next} previous={props.previous}/>;
        case 4:
            return <PaymentOrder paymentMethod={props.paymentMethod} next={props.next} previous={props.previous}/>;
        case 5:
            return <SummaryOrder profile={props.profile}
                                 shipping={props.shipping}
                                 billing={props.billing}
                                 paymentMethod={props.paymentMethod}
                                 previous={props.previous}
                                 next={props.next}/>;
        default:
            return null;
    }
};

export const StatusNavigate = (props) => (
    <div className={classNames(AppCSS.appResponsive, OrderCSS.statusNavigateButtonsContent)}>
        <div>
            {
                props.previous &&
                <button onClick={props.previous}
                        className={classNames(AppCSS.appButton, AppCSS.c1p5)}>{props.translate("previous")}
                </button>
            }
        </div>
        <div>
            {
                props.next &&
                <button
                    onClick={props.next}
                    className={classNames(AppCSS.appButton, AppCSS.c1p5)}>{props.translate("next")}
                </button>
            }
        </div>
    </div>
);

export const FinalizeNavigate = (props) => (
    <div className={classNames(AppCSS.appResponsive, OrderCSS.statusNavigateButtonsContent)}>
        <div>
            {
                props.previous &&
                <button onClick={props.previous}
                        className={classNames(AppCSS.appButton, AppCSS.c1p5)}>{props.translate("previous")}
                </button>
            }
        </div>
        <div>
            {
                props.next &&
                <button
                    onClick={props.next}
                    className={classNames(AppCSS.appButton, AppCSS.c2)}>{props.translate("finalize")}
                </button>
            }
        </div>
    </div>
);

const StatusBar = (props) => (
    <div className={OrderCSS.statusBar}><span className={AppCSS.c3}>{props.status}</span><span
        className={AppCSS.c3}>/</span><span
        className={AppCSS.c2}>5</span></div>
);

class Order extends Component {

    constructor(props) {
        super(props);

        this.state = {
            status: 1
        };

        this.profile = {
            firstName: this.props.getProfileData().getFirstName(),
            lastName: this.props.getProfileData().getLastName(),
            email: this.props.getProfileData().getEmail(),
        };

        this.shipping = {
            phoneNumber: this.props.getShippingData().getPhoneNumber(),
            zipCode: this.props.getShippingData().getZipCode(),
            city: this.props.getShippingData().getCity(),
            street: this.props.getShippingData().getStreet()
        };

        this.billing = {
            isBilling: false,
            name: this.props.getBillingData().getName(),
            taxNumber:this.props.getBillingData().getTaxNumber(),
            zipCode: this.props.getBillingData().getZipCode(),
            city: this.props.getBillingData().getCity(),
            street: this.props.getBillingData().getStreet()
        };

        this.paymentMethod = {
            id: null,
            name: null,
            shippingPrice: null
        };

        this.props.addTranslation(OrderJSON);
    }

    componentWillUnmount() {
        this.props.setEnableCart(true);
    }

    render() {
        return (
            <span>
                <SetMetaTags title={this.props.translate("order")}/>
                    <section className={StockCSS.white}>
                        <div className={StockCSS.stock}>
                            {this.state.status === 6 ?
                                <SucceededOrder/> :
                                <div>
                                    <StatusBar status={this.state.status}/>
                                    <StatusSelector profile={this.profile}
                                                    shipping={this.shipping}
                                                    billing={this.billing}
                                                    paymentMethod={this.paymentMethod}
                                                    previous={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        status: --prevState.status
                                                    }))}
                                                    next={() => this.setState(prevState => ({
                                                        ...prevState,
                                                        status: ++prevState.status
                                                    }))}
                                                    status={this.state.status}/>
                                </div>
                            }
                        </div>
                    </section>
            </span>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getProfileData: getProfileData(state.profile),
        getShippingData: getShippingData(state.shipping),
        getBillingData: getBillingData(state.billing)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation,
        setEnableCart
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(Order);
