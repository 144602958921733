import React from 'react';
import classNames from "classnames";

import AppCSS from "../App.scss";

const QuickBar = (props) => (
    <div className={AppCSS.appToolbar}>{props.children}</div>
);

const QuickLink = (props) => (
    <a className={classNames(AppCSS.appCircleButton, AppCSS.appImgButton, AppCSS.appToolbarItem)}
       href={props.href}
       target="_blank"
       title={props.title}>
        <img src={props.imgSrc}
             title={props.title}
             alt={props.title}/>
    </a>
);

const QuickButton = (props) => (
    <span className={classNames(AppCSS.appCircleButton, AppCSS.appImgButton, AppCSS.appToolbarItem)}
          onClick={props.onClick}
          title={props.title}>
        <img src={props.imgSrc}
             title={props.title}
             alt={props.title}/>
    </span>
);

export {QuickBar, QuickLink, QuickButton};