import SummaryCartContentCSS from "./SummaryCartContent.scss";
import SummaryOrderCSS from "./SummaryOrder.scss";
import classNames from "classnames";
import AppCSS from "../../App.scss";
import StockCSS from "../../components/Stock.scss";
import {Link} from "react-router-dom";
import React from "react";
import {getTranslate} from "react-localize-redux";
import {getCartContent} from "../actions/CartActions";
import {connect} from "react-redux";
import CartSumPrice from "../cart/CartSumPrice";

const SummaryCartContent = (props) => {
    const items = props.getCartContent();
    return (
        <div className={SummaryOrderCSS.summaryContent}>
            <div
                className={classNames(AppCSS.c1p5, StockCSS.blue, SummaryOrderCSS.summaryTitle)}>{props.translate("cart")}</div>
            <div>
                <SummaryCartListTitle translate={props.translate}/>
                <div>
                    {Object.keys(items).map(key => <SummaryCartItem key={key} {...items[key]}/>)}
                </div>
                <hr/>
                <CartSumPrice/>
            </div>
        </div>);
};

const SummaryCartListTitle = (props) => (
    <div className={SummaryCartContentCSS.summaryCartListTitle}>
        <div className={SummaryCartContentCSS.summaryCartItemPiece}>{props.translate("piece")}</div>
        <div className={SummaryCartContentCSS.summaryCartItemUnitPrice}>{props.translate("unitPrice")}</div>
        <div className={SummaryCartContentCSS.summaryCartItemSumPrice}>{props.translate("sumPrice")}</div>
    </div>
);

const SummaryCartItem = (props) => (
    <div className={SummaryCartContentCSS.summaryCartItem}>
        <Link to={"/shop/product/" + props.urlName} className={StockCSS.blue}>
            <div className={classNames(SummaryCartContentCSS.summaryCartItemName, StockCSS.blue)}>
                {props.productName}
            </div>
        </Link>
        <div className={SummaryCartContentCSS.summaryCartItemContent}>
            <div className={SummaryCartContentCSS.summaryCartItemPiece}>
                <span className={AppCSS.c1p5}>{props.piece}</span>
                <span>db</span>
            </div>
            <div className={SummaryCartContentCSS.summaryCartItemUnitPrice}><span
                className={AppCSS.c1p5}>{props.unitPrice}</span> Ft
            </div>
            <div className={SummaryCartContentCSS.summaryCartItemSumPrice}><span
                className={AppCSS.c1p5}>{props.unitPrice * props.piece}</span> Ft
            </div>
        </div>
    </div>
);


const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getCartContent: getCartContent(state.cart),
    };
};

export default connect(mapStateToProps, null)(SummaryCartContent);