export const defaultBillingData = {
    name: null,
    taxNumber:null,
    zipCode: null,
    city: null,
    street: null
};

export const setBillingData = (billingData) => (
    {type: "SET_BILLING_DATA", payload: billingData}
);

export const clearBillingData = () => (
    {
        type: "SET_BILLING_DATA", payload: defaultBillingData
    }
);

export const getBillingData = (state) => (
    () => (
        {
            getName: () => (state.name),
            getTaxNumber:()=>(state.taxNumber),
            getZipCode: () => (state.zipCode),
            getCity: () => (state.city),
            getStreet: () => (state.street)
        }
    )
);
