import InputCheckerCSS from './InputChecker.scss';

const defaultWarningClass = InputCheckerCSS.inputCheckerWarning;

const remove = (e) => {
    e.target.classList.remove(defaultWarningClass);
    e.target.removeEventListener("click", remove);
};

export default class InputChecker {

    constructor() {
        this.errors = 0;
    }

    setError = () => {
        this.errors++;
    };

    empty = (value) => {
        if (value !== null) {
            let type = typeof value;
            if (type === "boolean" || type === "number") {
                return false;
            } else if (type === "string") {
                if (value !== "") {
                    return false;
                }
            }
        }
        this.errors++;
        return true;
    };

    setErrorColor = (element) => {
        if (element) {
            element.classList.add(defaultWarningClass);
            element.addEventListener("click", remove, {passive: true});
        }
    };

    removeErrorColor = (element) => {
        if (element) {
            remove(element);
        }
    };

    isError = () => {
        return this.errors !== 0
    };

    clearError = () => {
        this.errors = 0;
    };

}
