import React, {Component} from "react";
import classNames from "classnames";
import AppCSS from "../../App.scss";
import StockCSS from "../../components/Stock.scss";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {addNotice} from "../../components/actions/NoticeActions";
import {connect} from "react-redux";
import {addPiece, isEnableCart} from "../actions/CartActions";
import InputChecker from "../../controllers/InputChecker";
import ProductCSS from "./Product.scss";

class AddProductToCartForm extends Component {

    constructor(props) {
        super(props);

        this.ic = new InputChecker();

    }

    addToCart = (e) => {
        e.preventDefault();

        let pieceParse = parseInt(e.target.elements.piece.value.toString());

        if (this.ic.empty(e.target.elements.piece.value) || isNaN(pieceParse) || pieceParse < 1) {
            this.ic.setErrorColor(e.target.elements.piece);
            this.props.addNotice(this.props.translate("incorrectPiece"));
        }

        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        if (this.props.isEnableCart()) {
            this.props.addPiece(this.props.product.productId, {
                productId: this.props.product.productId,
                piece: parseInt(e.target.elements.piece.value.toString()),
                unitPrice: this.props.product.unitPrice,
                productName: this.props.product.name,
                urlName: this.props.product.urlName
            });
            this.props.addNotice(this.props.translate("successfullyAddedToCart"));
        } else {
            this.props.addNotice(this.props.translate("theCartIsLocked"));
        }
    };

    render() {
        return (
            <form onSubmit={this.addToCart}>
                <div className={ProductCSS.productAddToCartBox}>
                    <div>
                        <input type="number"
                               className={classNames(AppCSS.appInput, StockCSS.white)}
                               name="piece"
                               defaultValue="1"
                               min={1}
                               step={1}/>
                        <span>db</span>
                    </div>
                    <div>
                        <button
                            className={classNames(AppCSS.appButton, AppCSS.c1)}>{this.props.translate("addToCart")}</button>
                    </div>
                </div>
            </form>
        );
    }

}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        isEnableCart: isEnableCart(state.cart)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addPiece,
        addNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(AddProductToCartForm);