import React, {Component, createRef} from 'react';
import ReactDOM from 'react-dom';

import LoadingCSS from "./components/Loading.scss";
import LoadingSVG from './images/svg-icons/loading.svg';
import LoadingScreenCSS from "./LoadingScreen.scss";

class LoadingScreen extends Component {

    constructor(props) {
        super(props);

        this.contentRef = createRef();
    }

    componentDidMount() {
        window.onload = () => {
            this.contentRef.current.style.opacity = "0";
            setTimeout(() => {
                this.contentRef.current.style.zIndex = "-999";
                while (this.contentRef.current.firstChild) {
                    this.contentRef.current.removeChild(this.contentRef.current.firstChild);
                }
            }, 550);
        };
    }

    render() {
        return (
            <div ref={this.contentRef} className={LoadingScreenCSS.loadingScreenContent}>
                <div style={{margin: "auto"}}>
                    <div className={LoadingCSS.loadingContent}>
                        <img className={LoadingCSS.loadingImg}
                             src={LoadingSVG}/>
                    </div>
                </div>
            </div>
        );
    }
}

ReactDOM.render(<LoadingScreen/>, document.getElementById('loadingScreen'));