const READ_SPEED = 500;
const PERCEIVE_TIME = 2500;
const TRANSITION_TIME = 500;

const getWordNum = (message) => READ_SPEED * message.split(" ").length;

export const NoticeReducer = (state = {
    messages: [],
    cls: false,
    time: 0
}, action) => {
    switch (action.type) {
        case "ADD_NOTICE":
            let time = state.time;

            if (time === 0) {
                time = PERCEIVE_TIME + TRANSITION_TIME;
            }

            let messages = state.messages;

            if (typeof action.payload === "string") {
                time += getWordNum(action.payload);
                messages.push(action.payload);
            } else {

                if (action.payload.length === 0) {
                    return state;
                }

                action.payload.forEach(message => {
                    time += getWordNum(message);
                    messages.push(message);
                });
            }
            return {messages: messages, cls: time > 5000, time: time};
        case "DROP_NOTICE":
            return {
                messages: [],
                cls: false,
                time: 0
            };
        default:
            return state;
    }
};