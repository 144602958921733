export const setUserSession = (token, id, expiration) => {
    localStorage.setItem("token", JSON.stringify(token));
    localStorage.setItem("expiration", JSON.stringify(expiration));
    localStorage.setItem("id", JSON.stringify(id));
    return {type: "SET_USER_SESSION", payload: {token, id, expiration}};
};

export const setToken = (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    return {type: "SET_USER_SESSION_TOKEN", payload: {token}};
};

export const getToken = (state) => (
    () => (state.token)
);

export const getID = (state) => (
    () => (state.id)
);

export const isExpToken = (state) => (
    () => !isNotExpToken(state)()
);

export const isNotExpToken = (state) => (
    () => (state.expiration > Date.now() / 1000)
);

export const loadUserSessionFromSessionStorage = () => {

    let token = localStorage.getItem("token");
    let id = localStorage.getItem("id");
    let expiration = localStorage.getItem("expiration");

    if (token && id && expiration) {
        return {
            token: JSON.parse(localStorage.getItem("token")),
            id: JSON.parse(localStorage.getItem("id")),
            expiration: JSON.parse(localStorage.getItem("expiration"))
        }
    }
    return {
        token: null,
        id: null,
        expiration: null
    }
};