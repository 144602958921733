import {Link} from "react-router-dom";
import ShopCSS from "./Shop.scss";
import NoGroupPictureSVG from "../../images/svg-icons/shop/group.svg";
import classNames from "classnames";
import AppCSS from "../../App.scss";
import StockCSS from "../../components/Stock.scss";
import React from "react";

const GroupItem = (props) => (
    <Link
        to={"/shop/products/" + props.group.paths.join("/") + (props.group.paths.length ? "/" : "") + props.group.urlName}>
        <div className={ShopCSS.shopItem}>
            <div className={ShopCSS.shopItemImage}
                 style={{
                     background: "url('" +
                         (props.group.src ? props.group.src : NoGroupPictureSVG)
                         + "') center center / cover no-repeat"
                 }}>
            </div>
            <div className={ShopCSS.shopItemFlip}>
                <div className={ShopCSS.shopItemFlipCard}>
                    <figure
                        className={classNames(ShopCSS.shopItemName, AppCSS.c1, StockCSS.white)}>{props.group.name}</figure>
                    <figure className={
                        classNames(ShopCSS.shopItemBack, ShopCSS.shopItemName, AppCSS.c1p5, StockCSS.black)
                    }>{props.group.name}</figure>
                </div>
            </div>
            {props.group.description && <div
                className={classNames(ShopCSS.shopItemContent)}>
                <div
                    className={classNames(StockCSS.emphasize, StockCSS.black, ShopCSS.shopItemDescriptionContent, ShopCSS.shopItemGroup)}>
                    <div>{props.group.description}</div>
                </div>
            </div>}
        </div>
    </Link>
);

export const GroupsList = (props) => (
    <div className={ShopCSS.shopItemsList}>
        {props.groups.map(group =>
            <GroupItem key={group.groupId} group={group}/>
        )}
    </div>
);