import {localizeReducer} from 'react-localize-redux';
import {NoticeReducer} from "./NoticeReducer";
import {DocViewerReducer} from "./DocViewerReducer";
import {PhotoViewerReducer} from "./PhotoViewerReducer";
import {CompanyReducer} from "./CompanyReducer";
import {ContactsReducer} from "./ContactsReducer";
import {UserSessionReducer} from "./UserSessionReducer";

export const GlobalReducers = {
    localize: localizeReducer,
    notice: NoticeReducer,
    docViewer: DocViewerReducer,
    photoViewer: PhotoViewerReducer,
    company: CompanyReducer,
    contacts: ContactsReducer,
    userSession: UserSessionReducer
};