import React, {Component, createRef} from "react";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import classNames from "classnames";
import {connect} from "react-redux";
import AppCSS from "../../App.scss";
import StockCSS from "../../components/Stock.scss";
import SummaryOrderCSS from "./SummaryOrder.scss";
import {addNotice} from "../../components/actions/NoticeActions";
import {FinalizeNavigate} from "./Order";
import ConfirmPopUp from "../../components/ConfirmPopUp";
import {deleteAllItems, getCartContent} from "../actions/CartActions";
import SummaryCartContent from "./SummaryCartContent";
import {getID, isExpToken, isNotExpToken} from "../../components/actions/UserSessionActions";
import GeneralTermsAndConditions from "../docs/GeneralTermsAndConditions";
import PrivacyPolicy from "../docs/PrivacyPolicy";
import Checkbox from "../../components/inputs/Checkbox";
import {api} from "../../controllers/API";

const ShippingSummaryContent = (props) => (
    <div className={SummaryOrderCSS.summaryContent}>
        <div
            className={classNames(AppCSS.c1p5, StockCSS.blue, SummaryOrderCSS.summaryTitle)}>{props.translate("orderShipping")}</div>
        <div className={classNames(AppCSS.appResponsive, AppCSS.verticalTop)}>
            <div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("yourFirstName")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.profile.firstName}</div>
                    <hr/>
                </div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("yourLastName")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.profile.lastName}</div>
                    <hr/>
                </div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("yourEmail")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.profile.email}</div>
                    <hr/>
                </div>
            </div>
            <div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("zipCode")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.shipping.zipCode}</div>
                    <hr/>
                </div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("city")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.shipping.city}</div>
                    <hr/>
                </div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("street")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.shipping.street}</div>
                    <hr/>
                </div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("phoneNumber")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.shipping.phoneNumber}</div>
                    <hr/>
                </div>
            </div>
        </div>
    </div>
);

const BillingSummaryContent = (props) => (
    <div className={SummaryOrderCSS.summaryContent}>
        <div
            className={classNames(AppCSS.c1p5, StockCSS.blue, SummaryOrderCSS.summaryTitle)}>{props.translate("orderBilling")}</div>
        <div className={classNames(AppCSS.appResponsive, AppCSS.verticalTop)}>
            <div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("billingName")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.billing.name}</div>
                    <hr/>
                </div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("billingTaxNumber")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.billing.taxNumber}</div>
                    <hr/>
                </div>
            </div>
            <div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("zipCode")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.billing.zipCode}</div>
                    <hr/>
                </div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("city")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.billing.city}</div>
                    <hr/>
                </div>
                <div className={SummaryOrderCSS.summaryItem}>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("street")}</div>
                    <div
                        className={classNames(SummaryOrderCSS.summaryItemData, AppCSS.c1p5)}>{props.billing.street}</div>
                    <hr/>
                </div>
            </div>
        </div>
    </div>
);

const PaymentSummaryContent = (props) => {

    let totalOrderPrice = 0;

    Object.keys(props.items).forEach(key => {
        totalOrderPrice += props.items[key].piece * props.items[key].unitPrice;
    });

    return (
        <div className={SummaryOrderCSS.summaryContent}>
            <div
                className={classNames(AppCSS.c1p5, StockCSS.blue, SummaryOrderCSS.summaryTitle)}>{props.translate("orderPayment")}</div>
            <div className={classNames(SummaryOrderCSS.summaryPaymentContent, AppCSS.verticalTop)}>
                <div>
                    <div className={classNames(SummaryOrderCSS.summaryItem, SummaryOrderCSS.summaryPaymentMethodName)}>
                        <div
                            className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("paymentMethod")}</div>
                        <div
                            className={classNames(AppCSS.c1p5)}>{props.paymentMethod.name}</div>
                    </div>
                </div>
                <div>
                    <div className={classNames(SummaryOrderCSS.summaryItem, SummaryOrderCSS.summaryShippingPrice)}>
                        <div
                            className={classNames(SummaryOrderCSS.summaryItemTitle, AppCSS.c1)}>{props.translate("shippingPrice")}</div>
                        <div
                            className={classNames(AppCSS.c1p5)}>{props.paymentMethod.shippingPrice} Ft
                        </div>
                    </div>
                </div>
                <div className={SummaryOrderCSS.summaryPaymentContentLine}>
                    <hr/>
                </div>
                <div
                    className={classNames(SummaryOrderCSS.summaryItem, AppCSS.c2, SummaryOrderCSS.summaryTotalOrderPriceTitle)}>{props.translate("totalOrderPrice")}</div>
                <div className={classNames(SummaryOrderCSS.summaryItem, SummaryOrderCSS.summaryTotalOrderPrice)}>
                    <div><span className={AppCSS.c2}>{totalOrderPrice + props.paymentMethod.shippingPrice}</span><span
                        className={AppCSS.c1p5}>Ft</span></div>
                </div>
            </div>
        </div>);
};

const GuestQuestion = (props) => (
    <div className={classNames(AppCSS.appResponsive, SummaryOrderCSS.summaryGuest)}>
        <div>
            <GeneralTermsAndConditions/>
            <div>{props.translate("generalTermsAndConditionsQuestion")}<Checkbox
                name="generalTermsAndConditionsWithOrder"/></div>
        </div>
        <div>
            <PrivacyPolicy/>
            <div>{props.translate("privacyPolicyQuestion")}<Checkbox
                name="privacyPolicyWithOrder"/></div>
        </div>
    </div>
);

class SummaryOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            confirmPopUpVisible: false
        };

        this.formRef = createRef();
    }

    customerDataToFormData = (formData, orderData, prefix) => {
        Object.keys(orderData).forEach(key => {
            const pref = prefix ? prefix + (key.charAt(0).toUpperCase() + key.slice(1)) : key;
            formData.append(pref, orderData[key]);
        });
    };

    placingAnOrder = () => {

        const formData = new FormData(this.formRef.current && this.formRef.current);

        this.customerDataToFormData(formData, this.props.profile);
        this.customerDataToFormData(formData, this.props.shipping, "shipping");
        this.customerDataToFormData(formData, this.props.billing, "billing");
        this.customerDataToFormData(formData, this.props.paymentMethod, "paymentMethod");

        const cart = [];
        const storeCart = this.props.getCartContent();

        Object.keys(storeCart).forEach(key => {
            const item = {
                productId: storeCart[key].productId,
                piece: storeCart[key].piece,
                unitPrice: storeCart[key].unitPrice,
                productName: storeCart[key].productName
            };
            cart.push(item);
        });

        formData.append("cart", JSON.stringify(cart));

        api.post((this.props.isNotExpToken() ? "/user/" + this.props.getID() : "") +
            "/order", formData).then(response => {
            if (response.data.status) {
                this.props.next();
            }
            this.props.addNotice(response.data.notice);
        }).catch(error => {
            console.log(error);
            this.props.addNotice(error.message);
        });

        this.setState(prevState => ({...prevState, confirmPopUpVisible: false}));
    };

    render() {
        return (
            <div>
                <h1 className={AppCSS.c3}>{this.props.translate("summary")}</h1>
                <p className={AppCSS.c1p5}>{this.props.translate("summaryDescription")}</p>
                <hr/>
                <ShippingSummaryContent translate={this.props.translate} profile={this.props.profile}
                                        shipping={this.props.shipping}/>
                {this.props.billing.isBilling &&
                <BillingSummaryContent translate={this.props.translate} billing={this.props.billing}/>}
                <SummaryCartContent/>
                <PaymentSummaryContent paymentMethod={this.props.paymentMethod}
                                       translate={this.props.translate}
                                       items={this.props.getCartContent()}/>
                {this.props.isExpToken() && <form onSubmit={(e) => e.preventDefault()} ref={this.formRef}>
                    <GuestQuestion translate={this.props.translate}/>
                </form>}
                <FinalizeNavigate previous={this.props.previous} next={() => (this.setState(prevState => ({
                    ...prevState,
                    confirmPopUpVisible: true
                })))} translate={this.props.translate}/>
                <ConfirmPopUp visible={this.state.confirmPopUpVisible}
                              question={this.props.translate("questionBeforePlacingAnOrder")}
                              noEvent={() => (this.setState(prevState => ({...prevState, confirmPopUpVisible: false})))}
                              yesEvent={this.placingAnOrder}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getCartContent: getCartContent(state.cart),
        isExpToken: isExpToken(state.userSession),
        isNotExpToken: isNotExpToken(state.userSession),
        getID: getID(state.userSession)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice,
        deleteAllItems
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(SummaryOrder);
