import React, {Component} from "react";
import AppCSS from "../../../App.scss";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import {addNotice} from "../../../components/actions/NoticeActions";

class SignUpActivation extends Component {

    constructor(props) {
        super(props);

        this.state = {notice: []};

        const formData = new FormData();
        formData.append("key", this.props.activationkey);

        axios.post("/api/activation/sign-up", formData).then(response => {
            this.props.setSuccess(response.data.status);
            this.props.addNotice(response.data.notice);
            this.setState(prevState => ({...prevState, notice: response.data.notice}));
        }).catch(error => {
            console.log(error);
            this.props.addNotice(error.message);
        });
    }

    render() {
        return (
            <div>
                <h2 className={AppCSS.c3}>{this.props.translate("signUpActivation")}</h2>
                {this.state.notice.map((notice, i) => <h3 key={i}>{notice}</h3>)}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(SignUpActivation);