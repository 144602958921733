export const PhotoViewerReducer = (state = {
    visible: false,
    group: null,
    startIndex: 0
}, action) => {
    switch (action.type) {
        case "SHOW_PHOTO_VIEWER":
            return {
                visible: true,
                group: action.payload.group,
                startIndex: action.payload.index
            };
        case "HIDE_PHOTO_VIEWER":
            return {visible: false, group: null, startIndex: 0};
        default:
            return state;
    }
};