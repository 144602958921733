import React, {Component} from "react";
import classNames from "classnames";
import AppCSS from "../../App.scss";

class Checkbox extends Component {

    constructor(props) {
        super(props);
        this.state = {checked: props.checked === true}
    };

    componentDidUpdate(prevProps) {
        if (prevProps.checked !== this.props.checked) {
            this.reverseChecked();
        }
    }

    reverseChecked = () => {
        this.setState(prevState => {
            if (this.props.checking) {
                this.props.checking(!prevState.checked);
            }
            return {checked: !prevState.checked};
        });
    };

    checkedIcon = () => {
        return this.props.checkedIcon ? this.props.checkedIcon : "check_box";
    };

    uncheckedIcon = () => {
        return this.props.uncheckedIcon ? this.props.uncheckedIcon : "check_box_outline_blank";
    };

    render() {
        return (
            <span className={AppCSS.appCheckboxBlock}>
                <label htmlFor={this.props.name} onClick={this.reverseChecked}>
                    <div className={classNames(AppCSS.appCircleButton, AppCSS.appCheckbox)}>
                        <i className={classNames(AppCSS.materialIcons, AppCSS.c2)}>{this.state.checked ? this.checkedIcon() : this.uncheckedIcon()}</i>
                    </div>
                </label>
                <input type="checkbox" defaultChecked={this.state.checked}
                       name={this.props.name} id={this.props.name}/>
            </span>
        );
    }
}

export default Checkbox;