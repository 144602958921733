import React, {Component} from 'react';
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Fade} from 'react-reveal';
import classNames from "classnames";

import InputChecker from '../../controllers/InputChecker';
import {addNotice} from "../../components/actions/NoticeActions";

import AppCSS from "../../App.scss";
import StockCSS from "../../components/Stock.scss";
import {api} from "../../controllers/API";

class WriteUsForm extends Component {

    constructor(props) {
        super(props);

        this.ic = new InputChecker();
    }

    sendMail = (e) => {
        e.preventDefault();

        if (this.ic.empty(e.target.elements.name.value)) {
            this.ic.setErrorColor(e.target.elements.name);
            this.props.addNotice(this.props.translate("emptyName"));
        }

        if (this.ic.empty(e.target.elements.fromEmail.value)) {
            this.ic.setErrorColor(e.target.elements.fromEmail);
            this.props.addNotice(this.props.translate("emptyEmail"));
        }

        if (this.ic.empty(e.target.elements.subject.value)) {
            this.ic.setErrorColor(e.target.elements.subject);
            this.props.addNotice(this.props.translate("emptySubject"));
        }

        if (this.ic.empty(e.target.elements.message.value)) {
            this.ic.setErrorColor(e.target.elements.message);
            this.props.addNotice(this.props.translate("emptyMessage"));
        }

        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        const formData = new FormData(e.target);

        api.post("/write-us", formData)
            .then(response => {
                this.props.addNotice(response.data.notice);
            })
            .catch(error => {
                console.log(error);
                this.props.addNotice(error.message);
            });

    };

    render() {
        return (
            <form onSubmit={this.sendMail}>
                <Fade right cascade>
                    <div>
                        <div>
                            <input type="text"
                                   className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                                   name="name"
                                   placeholder={this.props.translate("yourName")}/>
                        </div>
                        <div>
                            <input type="email"
                                   className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                                   name="fromEmail"
                                   placeholder={this.props.translate("yourEmail")}/>
                        </div>
                        <div>
                            <input type="text"
                                   className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                                   name="subject"
                                   placeholder={this.props.translate("subject")}/>
                        </div>
                        <div>
                        <textarea className={classNames(AppCSS.appInput, AppCSS.c2, StockCSS.white)}
                                  name="message"
                                  placeholder={this.props.translate("yourMessage")}/>
                        </div>
                        <button
                            className={classNames(AppCSS.appButton, AppCSS.c2)}>{this.props.translate("send")}</button>
                    </div>
                </Fade>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(WriteUsForm);