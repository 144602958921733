export const DocViewerReducer = (state = {
    visible: false,
    type: null,
    src: null
}, action) => {
    switch (action.type) {
        case "SHOW_DOC_VIEWER":
            return {
                visible: true,
                ...action.payload
            };
        case "HIDE_DOC_VIEWER":
            return {
                visible: false,
                type: null,
                src: null
            };
        default:
            return state;
    }
};