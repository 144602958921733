import React, {Component, createRef} from "react";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import classNames from "classnames";
import {connect} from "react-redux";
import AppCSS from "../../App.scss";
import InputChecker from "../../controllers/InputChecker";
import {StatusNavigate} from "./Order";
import {addNotice} from "../../components/actions/NoticeActions";

class ShippingOrder extends Component {

    constructor(props) {
        super(props);

        this.ic = new InputChecker();
        this.formRef = createRef();

    }

    saveShippingData = () => {

        if (this.ic.empty(this.formRef.current.elements.firstName.value)) {
            this.ic.setErrorColor(this.formRef.current.elements.firstName);
            this.props.addNotice(this.props.translate("emptyFirstName"));
        }

        if (this.ic.empty(this.formRef.current.elements.lastName.value)) {
            this.ic.setErrorColor(this.formRef.current.elements.lastName);
            this.props.addNotice(this.props.translate("emptyLastName"));
        }

        if (this.ic.empty(this.formRef.current.elements.email.value)) {
            this.ic.setErrorColor(this.formRef.current.elements.email);
            this.props.addNotice(this.props.translate("emptyEmail"));
        }

        if (this.ic.empty(this.formRef.current.elements.shippingZipCode.value)) {
            this.ic.setErrorColor(this.formRef.current.elements.shippingZipCode);
            this.props.addNotice(this.props.translate("emptyZipCode"));
        }

        if (this.ic.empty(this.formRef.current.elements.shippingCity.value)) {
            this.ic.setErrorColor(this.formRef.current.elements.shippingCity);
            this.props.addNotice(this.props.translate("emptyCity"));
        }

        if (this.ic.empty(this.formRef.current.elements.shippingStreet.value)) {
            this.ic.setErrorColor(this.formRef.current.elements.shippingStreet);
            this.props.addNotice(this.props.translate("emptyStreet"));
        }

        if (this.ic.empty(this.formRef.current.elements.shippingPhoneNumber.value)) {
            this.ic.setErrorColor(this.formRef.current.elements.shippingPhoneNumber);
            this.props.addNotice(this.props.translate("emptyPhoneNumber"));
        }


        if (this.ic.isError()) {
            this.ic.clearError();
            return;
        }

        const form = this.formRef.current.elements;
        this.props.profile.firstName = form.firstName.value;
        this.props.profile.lastName = form.lastName.value;
        this.props.profile.email = form.email.value;
        this.props.shipping.zipCode = form.shippingZipCode.value;
        this.props.shipping.city = form.shippingCity.value;
        this.props.shipping.street = form.shippingStreet.value;
        this.props.shipping.phoneNumber = form.shippingPhoneNumber.value;

        this.props.next();

    };

    render() {
        return (
            <div>
                <h1 className={AppCSS.c3}>{this.props.translate("orderShipping")}</h1>
                <p className={AppCSS.c1p5}>{this.props.translate("orderShippingDescription")}</p>
                <hr/>
                <form onSubmit={(e) => e.preventDefault()} ref={this.formRef}>
                    <div className={classNames(AppCSS.appResponsive, AppCSS.verticalTop)}>
                        <div>
                            <div>
                                <input type="text" defaultValue={this.props.profile.firstName}
                                       name="firstName"
                                       placeholder={this.props.translate("yourFirstName")}
                                       className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                            </div>
                            <div>
                                <input type="text" defaultValue={this.props.profile.lastName}
                                       name="lastName"
                                       placeholder={this.props.translate("yourLastName")}
                                       className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                            </div>
                            <div>
                                <input type="email" defaultValue={this.props.profile.email}
                                       name="email"
                                       placeholder={this.props.translate("yourEmail")}
                                       className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                            </div>
                        </div>
                        <div>
                            <div>
                                <input type="text" defaultValue={this.props.shipping.zipCode}
                                       maxLength="4"
                                       name="shippingZipCode" placeholder={this.props.translate("zipCode")}
                                       className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                            </div>
                            <div>
                                <input type="text" defaultValue={this.props.shipping.city}
                                       name="shippingCity"
                                       placeholder={this.props.translate("city")}
                                       className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                            </div>
                            <div>
                                <input type="text" defaultValue={this.props.shipping.street}
                                       name="shippingStreet"
                                       placeholder={this.props.translate("street")}
                                       className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                            </div>
                            <div>
                                <input type="text" defaultValue={this.props.shipping.phoneNumber}
                                       name="shippingPhoneNumber" placeholder={this.props.translate("phoneNumber")}
                                       className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                            </div>
                        </div>
                    </div>
                </form>
                <StatusNavigate next={this.saveShippingData} translate={this.props.translate}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(ShippingOrder);