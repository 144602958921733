import React, {Component, createRef} from "react";
import {getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import classNames from "classnames";
import {connect} from "react-redux";
import AppCSS from "../../App.scss";
import BillingOrderCSS from "./BillingOrder.scss";
import InputChecker from "../../controllers/InputChecker";
import {StatusNavigate} from "./Order";
import {addNotice} from "../../components/actions/NoticeActions";
import Checkbox from "../../components/inputs/Checkbox";

class BillingOrder extends Component {

    constructor(props) {
        super(props);

        this.ic = new InputChecker();
        this.formRef = createRef();
        this.state = {isBilling: this.props.billing.isBilling};

    }

    saveBillingData = () => {

        if (this.state.isBilling) {

            if (this.ic.empty(this.formRef.current.elements.billingName.value)) {
                this.ic.setErrorColor(this.formRef.current.elements.billingName);
                this.props.addNotice(this.props.translate("emptyName"));
            }

            if (this.ic.empty(this.formRef.current.elements.billingZipCode.value)) {
                this.ic.setErrorColor(this.formRef.current.elements.billingZipCode);
                this.props.addNotice(this.props.translate("emptyZipCode"));
            }

            if (this.ic.empty(this.formRef.current.elements.billingCity.value)) {
                this.ic.setErrorColor(this.formRef.current.elements.billingCity);
                this.props.addNotice(this.props.translate("emptyCity"));
            }

            if (this.ic.empty(this.formRef.current.elements.billingStreet.value)) {
                this.ic.setErrorColor(this.formRef.current.elements.billingStreet);
                this.props.addNotice(this.props.translate("emptyStreet"));
            }

            if (this.ic.isError()) {
                this.ic.clearError();
                return;
            }

            const form = this.formRef.current.elements;
            this.props.billing.name = form.billingName.value;
            this.props.billing.taxNumber = form.billingTaxNumber.value;
            this.props.billing.zipCode = form.billingZipCode.value;
            this.props.billing.city = form.billingCity.value;
            this.props.billing.street = form.billingStreet.value;
        } else {
            this.props.billing.name =
                this.props.billing.taxNumber =
                    this.props.billing.zipCode =
                    this.props.billing.city =
                        this.props.billing.street = null;
        }

        this.props.billing.isBilling = this.state.isBilling;
        this.props.next();
    };

    render() {
        return (
            <div>
                <div className={BillingOrderCSS.billingDataQuestionContent}>
                        <span
                            className={classNames(BillingOrderCSS.billingDataQuestionTitle, AppCSS.c1p5)}>
                            {this.props.translate("doYouWantToGiveBillingData1")}
                            </span>
                    <Checkbox
                        checked={this.props.billing.isBilling}
                        checking={() => this.setState(prevState => ({
                            ...prevState,
                            isBilling: !prevState.isBilling
                        }))}
                        name="isBilling"/>
                    <p>{this.props.translate("doYouWantToGiveBillingData2")}</p>
                </div>
                <div
                    className={classNames(BillingOrderCSS.billingDataContent, this.state.isBilling && BillingOrderCSS.visible)}>
                    <h1 className={AppCSS.c3}>{this.props.translate("orderBilling")}</h1>
                    <p className={AppCSS.c1p5}>{this.props.translate("orderBillingDescription")}</p>
                    <hr/>
                    <form onSubmit={(e) => e.preventDefault()} ref={this.formRef}>
                        <div className={classNames(AppCSS.appResponsive, AppCSS.verticalTop)}>
                            <div>
                                <div>
                                    <input type="text" defaultValue={this.props.billing.name}
                                           name="billingName"
                                           placeholder={this.props.translate("billingName")}
                                           className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                                </div>
                                <div>
                                    <input type="text" defaultValue={this.props.billing.taxNumber}
                                           name="billingTaxNumber"
                                           placeholder={this.props.translate("billingTaxNumber")}
                                           className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <input type="text" defaultValue={this.props.billing.zipCode}
                                           maxLength="4"
                                           name="billingZipCode" placeholder={this.props.translate("zipCode")}
                                           className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                                </div>
                                <div>
                                    <input type="text" defaultValue={this.props.billing.city}
                                           name="billingCity"
                                           placeholder={this.props.translate("city")}
                                           className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                                </div>
                                <div>
                                    <input type="text" defaultValue={this.props.billing.street}
                                           name="billingStreet"
                                           placeholder={this.props.translate("street")}
                                           className={classNames(AppCSS.appInput, AppCSS.c1p5)}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <StatusNavigate previous={this.props.previous} next={this.saveBillingData}
                                translate={this.props.translate}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addNotice
    }, dispatch);
};

export default connect(mapStateToProps, matchDispatchToProps)(BillingOrder);
