import React, {Component} from "react";
import {connect} from "react-redux";
import {getTranslate} from "react-localize-redux";
import classNames from "classnames";

import AppCSS from "../App.scss";
import ConfirmPopUpCSS from "./ConfirmPopUp.scss";
import StockCSS from "./Stock.scss";
import {PopUpFrame} from "./PopUp";

class ConfirmPopUp extends Component {
    yesEvent = () => {
        this.props.yesEvent();
    };

    noEvent = () => {
        this.props.noEvent();
    };

    render() {
        return (
            <PopUpFrame visible={this.props.visible}>
                <div className={classNames(ConfirmPopUpCSS.confirmPopUpContent, StockCSS.white)}>
                    <div>{this.props.question}</div>
                    <div className={AppCSS.appResponsive}>
                        <div>
                            <button onClick={this.yesEvent}
                                    className={classNames(AppCSS.appButton, AppCSS.c1)}>{this.props.translate("yes")}</button>
                        </div>
                        <div>
                            <button onClick={this.props.noEvent}
                                    className={classNames(AppCSS.appButton, AppCSS.c1)}>{this.props.translate("no")}</button>
                        </div>
                    </div>
                </div>
            </PopUpFrame>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize)
    };
};

export default connect(mapStateToProps, null)(ConfirmPopUp);