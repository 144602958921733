export const addContact = (key, firstName, lastName, email, phoneNumber) => (
    {
        type: "ADD_CONTACT", payload: {
            [key]: {
                firstName,
                lastName,
                email,
                phoneNumber
            }
        }
    }
);

export const getContact = (state) => (
    (key) => ({
        getFirstName: () => (state[key] ? state[key].firstName : null),
        getLastName: () => (state[key] ? state[key].lastName : null),
        getEmail: () => (state[key] ? state[key].email : null),
        getPhoneNumber: () => (state[key] ? state[key].phoneNumber : null)
    })
);