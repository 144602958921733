import React, {Component} from "react";
import CartJSON from "./Cart.json";
import classNames from "classnames";
import CartBlockCSS from "./CartBlock.scss";
import AppCSS from "../../App.scss";
import CartSVG from "../../images/svg-icons/shop/cart.svg";
import {addTranslation, getTranslate} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CartContent from "./CartContent";
import StockCSS from "../../components/Stock.scss";
import CartFooter from "./CartFooter";
import {getItemsCount} from "../actions/CartActions";

class CartBlock extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.props.addTranslation(CartJSON);
    }

    closeCartBlock = () => this.setState(prevState => ({...prevState, visible: !prevState.visible}));

    render() {
        return (
            <div className={classNames(CartBlockCSS.cartBlock, this.state.visible ? CartBlockCSS.visible : null)}>
                <span onClick={this.closeCartBlock}
                      className={classNames(AppCSS.appCircleButton, AppCSS.appImgButton, AppCSS.appToolbarItem, CartBlockCSS.cartButton)}
                      title={this.props.translate("cart")}>
                <img src={CartSVG} title={this.props.translate("cart")} alt={this.props.translate("cart")}/>
                </span>
                <div className={classNames(CartBlockCSS.cartBlockContent, StockCSS.white)}>
                    <div className={AppCSS.c1p5}>{this.props.translate("cart")}</div>
                    <hr/>
                    <CartContent/>
                    {this.props.getItemsCount() !== 0 && <CartFooter closeCartBlock={this.closeCartBlock}/>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        translate: getTranslate(state.localize),
        getItemsCount: getItemsCount(state.cart)
    };
};

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        addTranslation
    }, dispatch);
};


export default connect(mapStateToProps, matchDispatchToProps)(CartBlock);
