import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {addTranslation, initialize} from 'react-localize-redux';
import axios from "axios";
import * as serviceWorker from "./serviceWorker";

import GuestReducers from './guest/reducers/GuestReducers';
import {
    setCity,
    setName,
    setRegisteredName,
    setRegistryNumber,
    setStreet,
    setTaxNumber,
    setZipCode
} from "./components/actions/CompanyActions";
import {addContact} from "./components/actions/ContactsActions";
import {addNotice} from "./components/actions/NoticeActions";
import App from './guest/App';

import GlobalJSON from "./languages/Global";
import FormsJSON from "./languages/Forms";
import {api} from "./controllers/API";
import {applyMiddleware, createStore} from 'redux';

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
    const {logger} = require(`redux-logger`);
    middlewares.push(logger);
}

const middleware = applyMiddleware(...middlewares);
export const store = createStore(GuestReducers, middleware);

store.dispatch(addTranslation(GlobalJSON));
store.dispatch(addTranslation(FormsJSON));

store.dispatch(initialize({
    languages: [{code: 'hu', name: 'Hungarian'}],
    options: {
        defaultLanguage: "hu",
        renderToStaticMarkup: false,
        onMissingTranslation: () => {
            if (store.getState().localize.translations["notAvailableInLanguage"]) {
                return store.getState().localize.translations["notAvailableInLanguage"][0];
            } else {
                return "Wait for translation...";
            }
        }
    }
}));

axios.get("/languages/Forms.json").then(response => {
    store.dispatch(addTranslation(response.data));
}).catch(error => {
    console.log(error);
    store.dispatch(addNotice(error.message));
});

axios.get("/languages/Menu.json").then(response => {
    store.dispatch(addTranslation(response.data));
}).catch(error => {
    console.log(error);
    store.dispatch(addNotice(error.message));
});

axios.get("/languages/Shop.json").then(response => {
    store.dispatch(addTranslation(response.data));
}).catch(error => {
    console.log(error);
    store.dispatch(addNotice(error.message));
});

api.get("/company-data").then(response => {
    if (response.data.status) {
        const companyData = response.data.data.companyData;
        store.dispatch(setName(companyData.name));
        store.dispatch(setRegisteredName(companyData.registeredName));
        store.dispatch(setZipCode(companyData.zipCode));
        store.dispatch(setCity(companyData.city));
        store.dispatch(setStreet(companyData.street));
        store.dispatch(setTaxNumber(companyData.taxNumber));
        store.dispatch(setRegistryNumber(companyData.registryNumber));

        if (companyData.contacts.length) {
            companyData.contacts.forEach(contact =>
                store.dispatch(addContact(contact.key, contact.firstName, contact.lastName, contact.email, contact.phoneNumber))
            );
        }
    }
}).catch(error => {
    console.log(error);
    store.dispatch(addNotice(error.message));
});

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
