import React, {Component, createRef} from "react";
import APIProgressBarCSS from "./APIProgressBar.scss";
import APIProgress from "../controllers/APIProgress";

export default class APIProgressBar extends Component {

    constructor(props) {
        super(props);
        this.app = new APIProgress(this.setStart, this.setIncrease, this.setEnd);
        this.line = new createRef();

        this.minPercent = 5;
        this.maxPercent = 95;
        this.percent = 0;
        this.interval = null;
        this.timeout = null;
    }

    setStart = () => {
        this.line.current.style.width = this.percent + "%";
        this.line.current.classList.add(APIProgressBarCSS.APIProgressBarTransition);
        this.line.current.classList.remove(APIProgressBarCSS.APIProgressBarTransitionEnd);
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        this.interval = setInterval(this.setIncrease, 500);
    };

    setIncrease = () => {
        let calcStep = Math.round((this.maxPercent - this.percent) / 2);
        this.percent += calcStep > 10 ? 10 : calcStep;
        if (this.minPercent < this.percent && this.maxPercent > this.percent) {
            this.line.current.style.width = this.percent + "%";
        }
    };

    setEnd = () => {
        this.line.current.classList.add(APIProgressBarCSS.APIProgressBarTransitionEnd);
        this.line.current.style.width = "";
        this.timeout = setTimeout(() => {
            this.line.current.classList.remove(APIProgressBarCSS.APIProgressBarTransition, APIProgressBarCSS.APIProgressBarTransitionEnd);
            this.percent = this.minPercent;
        }, 1000);
        clearInterval(this.interval);
    };

    render() {
        return (
            <div className={APIProgressBarCSS.APIProgressBar}>
                <hr ref={this.line}/>
            </div>
        );
    }
}